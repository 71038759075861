import { useCallback, useEffect, useState } from 'react';
import { WalletContext } from '@/context/wallet';
import { getContracts } from '@/config';

function useSupportTokens(props) {
  const { supportTokens } = props ?? {};
  const { chainId } = WalletContext.useContainer();

  const [tokens, setTokens] = useState([]);

  const getTokens = useCallback(() => {
    const tokens = getContracts(chainId).tokens;
    setTokens(tokens?.filter(token => supportTokens?.includes(token.symbol)) ?? []);
  }, [chainId, supportTokens]);

  useEffect(() => {
    getTokens();
  }, [getTokens]);

  return { tokens };
}

export default useSupportTokens;
