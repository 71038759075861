import { useCallback } from 'react';
import { GelatoOpsSDK } from '@gelatonetwork/ops-sdk';
import { isGelatoOpsSupported } from '@gelatonetwork/ops-sdk';
import { WalletContext } from '@/context/wallet';

// {
//   name: '',
//   execAddress: '',
//   execSelector: '',
//   execData: '',
//   execAbi: ''
//   resolverAddress: '',
//   resolverData: '',
//   resolverAbi: '',
//   startTime: 11,
//   interval: 24 * 60 * 60,
//   useTreasury: true,
//   dedicatedMsgSender: true,
//   singleExec: false,
//   offChainResolverHash: '';
//     offChainResolverArgs: {

//     },
// }

// wrap://ipfs/Qmd9WgbLp34PmWSYG7troNcpEED4UwRzqgnfEpABZSpuD6
// wrap://ipfs/QmTNk1Uae5mWaBCk46KCnMVnntnDSTGtdetJiFRS2bXSvX
// Vault new
// wrap://ipfs/QmQoYd6dPTiG7WD3VYGDMHptLemU7LsAFeb16LdUibWifg

function useGelato() {
  const { chainId, provider } = WalletContext.useContainer();

  const getGelato = useCallback(() => {
    if (!isGelatoOpsSupported(chainId)) {
      // console.log(`Gelato Ops network not supported (${chainId})`);
      return;
    }
    if (provider == null) {
      return;
    }
    const signer = provider.getSigner();
    const gelatoOps = new GelatoOpsSDK(chainId, signer);

    // console.log(gelatoOps);
    return gelatoOps;
  }, [chainId, provider]);

  const createTask = useCallback(
    async params => {
      const gelatoOps = getGelato();
      if (gelatoOps == null) {
        return;
      }
      const { taskId, tx } = await gelatoOps.createTask(params);
      await tx.wait();
      // console.log(`Task created, taskId: ${taskId} (tx hash: ${tx.hash})`);
      return { taskId, tx };
    },
    [getGelato]
  );

  const getDedicatedMsgSender = useCallback(async () => {
    const gelatoOps = getGelato();
    if (gelatoOps == null) {
      return;
    }
    const { address } = await gelatoOps.getDedicatedMsgSender();
    return address;
  }, [getGelato]);

  const activeTasks = useCallback(async () => {
    const gelatoOps = getGelato();
    if (gelatoOps == null) {
      return;
    }
    return await gelatoOps.getActiveTasks();
  }, [getGelato]);

  const cancelTask = useCallback(
    async taskId => {
      const gelatoOps = getGelato();
      if (gelatoOps == null) {
        return;
      }
      const { tx } = await gelatoOps.cancelTask(taskId);
      await tx.wait();
    },
    [getGelato]
  );

  return {
    createTask,
    getDedicatedMsgSender,
    activeTasks,
    cancelTask,
  };
}

export default useGelato;
