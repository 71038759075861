import BigNumber from 'bignumber.js';

import { isNumberish } from './format';

const generateOperationFn = op => (x, y) =>
  isNumberish(x) && isNumberish(y) ? BigNumber(x)[op](y).toString() : undefined;

export const bigPlus = generateOperationFn('plus');
export const bigMinus = generateOperationFn('minus');
export const bigMultiply = generateOperationFn('times');
export const bigDivide = generateOperationFn('div');

export const isZero = x => (isNumberish(x) ? BigNumber(x).isZero() : undefined);
