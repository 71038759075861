import { ReactComponent as BackImg } from '@/assets/back.svg';
import { useNavigate } from 'react-router-dom';

function Back(props) {
  const navigate = useNavigate();
  return (
    <button className=" flex cursor-pointer items-center text-indigo-300" onClick={() => navigate(-1)}>
      <i className="w-6">
        <BackImg />
      </i>
      Back
    </button>
  );
}

export default Back;
