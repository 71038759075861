import { useCallback, useLayoutEffect, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

function Modal(props) {
  const { children, className, visible, onClose } = props;

  const [ele] = useState(() => {
    const defaultEle = document.createElement('div');
    defaultEle.setAttribute(
      'class',
      'fixed top-0 left-0 bottom-0 right-0 z-50 flex flex-col items-center justify-center bg-slate-500/70'
    );
    return defaultEle;
  });

  const create = useCallback(() => {
    if (!ele.parentElement) {
      document.body.appendChild(ele);
      ele.addEventListener('click', onClose);
    }
  }, [ele, onClose]);

  const cleanup = useCallback(() => {
    ele.removeEventListener('click', onClose);
    ele.parentElement?.removeChild(ele);
  }, [ele, onClose]);

  useLayoutEffect(() => {
    if (visible) {
      create();
    } else {
      cleanup();
    }
    return cleanup;
  }, [visible, create, cleanup]);

  useEffect(() => {
    document.body.style.overflow = visible ? 'hidden' : 'unset';
  }, [visible]);

  return ReactDOM.createPortal(
    <div
      className={classNames('rounded-xl bg-slate-800 text-white', className ?? 'w-80 lg:w-96')}
      onClick={e => {
        let event = e;
        if (!event) {
          event = window.event;
        }
        event.nativeEvent.cancelBubble = true;
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
      }}
    >
      {children}
    </div>,
    ele
  );
}

export default Modal;
