import { useCallback, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { addDays } from 'date-fns';

import useContract from './useContract';
import ERC20ABI from '@/abi/ERC20.json';
import VaultABI from '@/abi/Vault.json';
import IronBankMonitorABI from '@/abi/monitor/IronBankMonitor.json';
import IronBankSupplyABI from '@/abi/IronBankSupply.json';
import StakingRewardsHelperABI from '@/abi/StakingRewardsHelper.json';
import { WalletContext } from '@/context/wallet';
import useGasLimit from './useGasLimit';
import toast from '@/utils/toast';
import useGelato from './useGelato';

function useIronBankSupply(props) {
  const { logic, monitor } = props ?? {};
  const {
    account,
    //  chainId
  } = WalletContext.useContainer();
  const { getContract } = useContract();
  const { calcGasLimit } = useGasLimit();

  const [vault, setVault] = useState();
  const [token, setToken] = useState();
  const [amount, setAmount] = useState('');
  const [robot, setRobot] = useState('0x5Ca91327dF003aFA2207b374D5f19730CCa67e2C');
  const [decimals, setDecimals] = useState();
  const [vaultBalance, setVaultBalance] = useState();
  const [stakedBalance, setStakedBalance] = useState();
  const [rewards, setRewards] = useState();
  const [rewardsBalance, setRewardsBalance] = useState();
  const [swapLogic, setSwapLogic] = useState();
  const [executor, setExecutor] = useState();

  const [asset, setAsset] = useState();
  const [liquidity, setLiquidity] = useState('');

  const [pool, setPool] = useState();
  const getBalance = useCallback(async () => {
    setVaultBalance(null);
    const contract = getContract(token, ERC20ABI);
    if (contract == null) {
      return;
    }
    if (account == null || vault == null) {
      return;
    }
    try {
      const [balance1, _decimals] = await Promise.all([contract.balanceOf(vault), contract.decimals()]);
      setVaultBalance(ethers.utils.formatUnits(balance1, _decimals));
      setDecimals(_decimals);
    } catch (err) {
      console.log(err);
    }
  }, [getContract, token, account, vault]);

  const getPool = useCallback(async () => {
    const contract = getContract(logic, IronBankSupplyABI);
    if (contract == null) {
      setPool(null);
      return;
    }
    try {
      const _pool = await contract.pool();
      setPool(_pool);
    } catch (err) {
      console.log(err);
      setPool(null);
    }
  }, [getContract, logic]);

  const getDetail = useCallback(async () => {
    setStakedBalance(null);
    const contract = getContract(pool, StakingRewardsHelperABI);
    if (contract == null) {
      return;
    }
    if (vault == null) {
      return;
    }
    try {
      const stakedList = await contract.getUserStaked(vault);
      setStakedBalance(stakedList);
    } catch (err) {
      console.log(err);
    }
  }, [getContract, pool, vault]);

  const getRewards = useCallback(async () => {
    setRewardsBalance(null);
    const contract = getContract(pool, StakingRewardsHelperABI);
    if (contract == null) {
      return;
    }
    if (vault == null) {
      return;
    }
    if (rewards == null || rewards.length === 0) {
      return;
    }
    try {
      const list = await contract.getUserClaimableRewards(vault, rewards);
      setRewardsBalance(list);
    } catch (err) {
      console.log(err);
    }
  }, [getContract, pool, vault, rewards]);

  const enter = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const _amount = ethers.utils.parseUnits(amount, decimals);
      const _vaultBalance = ethers.utils.parseUnits(vaultBalance, decimals);
      if (_amount.gt(_vaultBalance)) {
        throw new Error('Input exceeds vault balance');
      }
      const iface = new ethers.utils.Interface(IronBankSupplyABI);
      const args = iface.encodeFunctionData('stake', [token, _amount]);
      const gasLimit = await contract.estimateGas.execute(logic, args);
      const tx = await contract.execute(logic, args, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      getBalance();
      getDetail();
      setAmount('');
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, logic, token, amount, vaultBalance, decimals, calcGasLimit, getBalance, getDetail]);

  const exit = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const iface = new ethers.utils.Interface(IronBankSupplyABI);
      const args = iface.encodeFunctionData('exit', [token]);
      const gasLimit = await contract.estimateGas.execute(logic, args);
      const tx = await contract.execute(logic, args, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      getBalance();
      getDetail();
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, logic, token, calcGasLimit, getBalance, getDetail]);

  const exitAll = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const iface = new ethers.utils.Interface(IronBankSupplyABI);
      const args = iface.encodeFunctionData('exitAll');
      const gasLimit = await contract.estimateGas.execute(logic, args);
      const tx = await contract.execute(logic, args, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      getBalance();
      getDetail();
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, logic, calcGasLimit, getBalance, getDetail]);

  const claim = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const iface = new ethers.utils.Interface(IronBankSupplyABI);
      const args = iface.encodeFunctionData('claim');
      const gasLimit = await contract.estimateGas.execute(logic, args);
      const tx = await contract.execute(logic, args, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      await getRewards();
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, logic, getRewards, calcGasLimit]);

  const approve = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const authorized = await contract.hasImplementation(robot, logic);
      if (authorized) {
        throw new Error('authorized');
      }
      const gasLimit = await contract.estimateGas.grantImplementation(robot, logic);
      const tx = await contract.grantImplementation(robot, logic, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      setRobot('');
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, robot, logic, calcGasLimit]);

  const approveSwap = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const authorized = await contract.hasImplementation(robot, swapLogic);
      if (authorized) {
        throw new Error('authorized');
      }
      const gasLimit = await contract.estimateGas.grantImplementation(robot, swapLogic);
      const tx = await contract.grantImplementation(robot, swapLogic, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      setRobot('');
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, robot, swapLogic, calcGasLimit]);

  const { createTask, getDedicatedMsgSender } = useGelato();

  const getExecutor = useCallback(async () => {
    try {
      const sender = await getDedicatedMsgSender();
      setExecutor(sender);
    } catch (err) {
      setExecutor();
    }
  }, [getDedicatedMsgSender]);

  const createClaimTask = useCallback(async () => {
    try {
      const vaultFace = new ethers.utils.Interface(VaultABI);
      const selector = vaultFace.getSighash(vaultFace.getFunction('execute'));
      const logicFace = new ethers.utils.Interface(IronBankSupplyABI);
      const args = logicFace.encodeFunctionData('claim');
      const data = vaultFace.encodeFunctionData('execute', [logic, args]);
      const date = addDays(new Date(), 1);
      date.setUTCHours(3, 0, 0, 0);
      const { tx } = await createTask({
        name: 'IronBank Claim',
        execAddress: vault,
        execSelector: selector,
        execData: data,
        startTime: date.getTime() / 1000,
        interval: 24 * 60 * 60,
        useTreasury: true,
        dedicatedMsgSender: true,
      });
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [createTask, vault, logic]);

  // const swapFuncHash = 'Qme64zpMvdfNz8ekpSNFPxmgPeZ6quAqAvV2pLxTWAVaSm';
  // const createSwapTask = useCallback(async () => {
  //   try {
  //     const vaultFace = new ethers.utils.Interface(VaultABI);
  //     const selector = vaultFace.getSighash(vaultFace.getFunction('execute'));
  //     const { tx } = await createTask({
  //       name: 'IronBank Swap',
  //       execAddress: vault,
  //       execSelector: selector,
  //       startTime: 0,
  //       useTreasury: true,
  //       dedicatedMsgSender: true,
  //       web3FunctionHash: swapFuncHash,
  //       web3FunctionArgs: {
  //         chainId: `${chainId}`,
  //         targetAddress: vault,
  //         logicAddress: logic,
  //       },
  //     });
  //     toast.success('Success');
  //     return tx;
  //   } catch (err) {
  //     console.log(err);
  //     toast.error(err?.reason ?? err?.message ?? 'Error');
  //   }
  // }, [createTask, vault, chainId, logic]);

  const createExitTask = useCallback(async () => {
    try {
      const vaultFace = new ethers.utils.Interface(VaultABI);
      const selector = vaultFace.getSighash(vaultFace.getFunction('execute'));
      // resolver
      const resolverFace = new ethers.utils.Interface(IronBankMonitorABI);
      // address _vault,
      // address _underlying,
      // uint256 _cashThreshold,
      // string memory _underlyingSymbol,
      // uint256 _ratio 100

      if (asset == null) {
        throw new Error('select a asset first');
      }
      const underlying = asset.address;
      let symbol = asset.symbol;
      if (symbol === 'WETH') {
        symbol = 'ETH';
      }
      if (symbol === 'WBTC') {
        symbol = 'BTC';
      }
      const contract = getContract(underlying, ERC20ABI);
      const decimals = await contract.decimals();
      const _liquidity = ethers.utils.parseUnits(liquidity, decimals);

      const resolverData = resolverFace.encodeFunctionData('checker(address,address,uint256,string,uint256)', [
        vault,
        underlying,
        _liquidity,
        symbol,
        15,
      ]);
      const { tx } = await createTask({
        name: 'IronBank Exit',
        execAddress: vault,
        execSelector: selector,
        startTime: 0,
        useTreasury: true,
        dedicatedMsgSender: true,
        resolverAddress: monitor[0],
        resolverData: resolverData,
        // resolverAbi: JSON.stringify(counterAbi),
      });
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [createTask, vault, asset, monitor, liquidity, getContract]);

  const approveAutomate = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const authorized = await contract.hasImplementation(executor, logic);
      if (authorized) {
        throw new Error('authorized');
      }
      const gasLimit = await contract.estimateGas.grantImplementation(executor, logic);
      const tx = await contract.grantImplementation(executor, logic, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [calcGasLimit, executor, getContract, logic, vault]);

  useEffect(() => {
    getBalance();
  }, [getBalance]);

  useEffect(() => {
    getPool();
  }, [getPool]);

  useEffect(() => {
    getDetail();
  }, [getDetail]);

  useEffect(() => {
    getRewards();
  }, [getRewards]);

  useEffect(() => {
    getExecutor();
  }, [getExecutor]);

  return {
    vault,
    setVault,
    token,
    setToken,
    setRewards,
    setSwapLogic,
    amount,
    setAmount,
    asset,
    setAsset,
    liquidity,
    setLiquidity,
    robot,
    setRobot,
    vaultBalance,
    stakedBalance,
    rewardsBalance,
    enter,
    exit,
    exitAll,
    claim,
    approve,
    approveSwap,
    createClaimTask,
    // createSwapTask,
    createExitTask,
    approveAutomate,
    executor,
  };
}

export default useIronBankSupply;
