import BigNumber from 'bignumber.js';

export const BLANK_CHAR = '--';

export function isNumberish(value) {
  return (
    value != null &&
    (typeof value === 'number' ||
      typeof value === 'bigint' ||
      (typeof value === 'string' && !!value.match(/^[-+]?([0-9]+(\.[0-9]*)?|\.[0-9]+)([eE][-+]?[0-9]+)?$/)))
  );
}

BigNumber.config({
  // DECIMAL_PLACES,
  ROUNDING_MODE: BigNumber.ROUND_DOWN,
  EXPONENTIAL_AT: [-1e9, 1e9], // [-7, 20],
  // RANGE: [-1e9, 1e9],
  // CRYPTO: false,
  // MODULO_MODE: 1,
  // POW_PRECISION: 0,
  // FORMAT: {
  //   // string to prepend
  //   prefix: '',
  //   // decimal separator
  //   decimalSeparator: '.',
  //   // grouping separator of the integer part
  //   groupSeparator: ',',
  //   // primary grouping size of the integer part
  //   groupSize: 3,
  //   // secondary grouping size of the integer part
  //   secondaryGroupSize: 0,
  //   // grouping separator of the fraction part
  //   fractionGroupSeparator: ' ',
  //   // grouping size of the fraction part
  //   fractionGroupSize: 0,
  //   // string to append
  //   suffix: '',
  // },
  // ALPHABET: '0123456789abcdefghijklmnopqrstuvwxyz',
});

const bigNumberFormat = options => {
  const { approximate, thousands, prefix, suffix } = options;
  return {
    prefix: approximate ? '≈ ' : prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: thousands ? 3 : 0,
    secondaryGroupSize: 0,
    fractionGroupSeparator: ' ',
    fractionGroupSize: 0,
    suffix: suffix,
  };
};

export const formatNumber = (value, options = {}) => {
  const opts = { places: 8, thousands: false, approximate: false, ...options };
  const { rm = BigNumber.ROUND_DOWN } = options;
  if (!isNumberish(value)) {
    return opts.nilDisabled ? value : BLANK_CHAR;
  }
  const number = new BigNumber(value);
  const fmt = bigNumberFormat(opts);
  if (isNumberish(opts?.segment?.value) && number.lt(new BigNumber(opts?.segment?.value))) {
    opts.places = opts?.segment?.places;
  }
  return number.decimalPlaces(opts.places, rm).toFormat(fmt);
};

export const formatPercentages = (value, options = {}) => {
  const { sign = false } = options;
  const { rm = BigNumber.ROUND_DOWN } = options;
  const opts = { places: 2, thousands: false, suffix: '%', options };
  if (!isNumberish(value)) {
    return opts.nilDisabled ? value : BLANK_CHAR;
  }
  const number = new BigNumber(value);
  const numberFormat = number.times(100).decimalPlaces(opts.places, rm);
  if (sign) {
    const zero = new BigNumber('0');
    if (numberFormat.comparedTo(zero) > 0) {
      opts.prefix = '+';
    } else if (numberFormat.comparedTo(zero) < 0) {
      opts.prefix = '';
    } else {
      opts.prefix = '';
    }
  }
  const fmt = bigNumberFormat(opts);
  return numberFormat.toFormat(fmt);
};

export const formatCurrency = (value, options = {}) => {
  const opts = { places: 2, thousands: false, prefix: '$ ', ...options };
  const { rm = BigNumber.ROUND_DOWN } = options;
  if (!isNumberish(value)) {
    return opts.nilDisabled ? value : BLANK_CHAR;
  }
  const number = new BigNumber(value);
  if (number.gt(0) && opts.places === 2) {
    if (number.lt(0.001)) {
      opts.places = 4;
    } else if (number.lt(0.01)) {
      opts.places = 3;
    }
  }
  const fmt = bigNumberFormat(opts);
  return number.decimalPlaces(opts.places, rm).toFormat(fmt);
};
