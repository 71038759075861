import { useCallback, useEffect, useState } from 'react';
import { WalletContext } from '@/context/wallet';
import { getContracts } from '@/config';

function useTokens() {
  const { chainId } = WalletContext.useContainer();

  const [tokens, setTokens] = useState([]);

  const getTokens = useCallback(() => {
    const tokens = getContracts(chainId).tokens;
    setTokens(tokens ?? []);
  }, [chainId]);

  useEffect(() => {
    getTokens();
  }, [getTokens]);

  return { tokens };
}

export default useTokens;
