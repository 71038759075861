import { WalletContext } from '@/context/wallet';
import { useCallback } from 'react';

function useGasLimit() {
  const { chainId } = WalletContext.useContainer();

  const calcGasLimit = useCallback(
    gasLimit => {
      let result = gasLimit;
      if ([42161].includes(chainId)) {
        result = result.mul(20);
      } else if ([69].includes(chainId)) {
        result = result.mul(10);
      } else {
        result = result.mul(12);
      }
      return result.div(10);
    },
    [chainId]
  );

  return { calcGasLimit };
}

export default useGasLimit;
