import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Link } from '@/assets/link.svg';
import Back from '@/components/Back';
import Button from '@/components/Button';
import Input from '@/components/Input';
import { getBlockExplorerUrl } from '@/config';
import { ellipsizeText } from '@/utils/input';
import useVault from '@/hooks/useVault';
import { WalletContext } from '@/context/wallet';
import SelectToken from '@/components/SelectToken';
import useLoading from '@/hooks/useLoading';
import useTokens from '@/hooks/useTokens';

function App() {
  const { state } = useLocation();
  const { name, address } = state ?? {};
  const { chainId } = WalletContext.useContainer();

  const blockExplorerUrl = getBlockExplorerUrl(chainId);

  const {
    vault,
    setVault,
    setDepositToken,
    amount,
    setAmount,
    setWithdrawToken,
    walletBalance,
    vaultBalance,
    deposit,
    withdraw,
  } = useVault();

  useEffect(() => {
    setVault(address);
  }, [setVault, address]);

  const chainIdRef = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    if (chainIdRef.current == null) {
      chainIdRef.current = chainId;
    } else {
      if (chainIdRef.current !== chainId) {
        navigate('/', { replace: true });
      }
    }
  }, [chainId, navigate]);

  const { tokens } = useTokens();

  const { loading: depositLoading, fn: depositFn } = useLoading(deposit);
  const { loading: withdrawLoading, fn: withdrawFn } = useLoading(withdraw);

  return (
    <div>
      <Back />
      <div className="mt-5">
        <h1 className="text-2xl font-bold">{name}</h1>
        <div className="mt-2 flex items-center gap-2">
          <span>Contract:</span>
          <a
            href={`${blockExplorerUrl}/address/${vault}`}
            className="flex items-center gap-2 hover:opacity-70"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
              {ellipsizeText({ text: vault })}
            </span>
            <i className="w-4 opacity-30">
              <Link />
            </i>
          </a>
        </div>
        <p className="mt-2 opacity-30">Vault is xxx</p>
      </div>
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Deposit</h3>
        <SelectToken
          tokens={tokens}
          onChange={token => {
            setDepositToken(token?.address);
          }}
          tokenKey="depositTokenIndex"
        />
        <p className="mt-2 text-sm text-white/70">{`Wallet balance: ${walletBalance ?? '--'}`}</p>
        <div className="mt-6 flex items-center justify-between gap-4">
          <Input
            onMax={() => {
              setAmount(walletBalance ?? '');
            }}
            onChange={event => {
              setAmount(event.target.value);
            }}
            value={amount}
          />
          <Button className="min-w-[96px]" loading={depositLoading} onClick={depositFn}>
            Deposit
          </Button>
        </div>
      </div>
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Withdraw</h3>
        <SelectToken
          tokens={tokens}
          onChange={token => {
            setWithdrawToken(token?.address);
          }}
          tokenKey="withdrawTokenIndex"
        />
        <p className="mt-2 text-sm text-white/70">{`Vault balance: ${vaultBalance ?? '--'}`}</p>
        {/* <div className="mt-6 flex items-center justify-between gap-4">
          <Input onMax={() => {}} />
        </div> */}
        <Button className="mt-6 min-w-[96px]" loading={withdrawLoading} onClick={withdrawFn}>
          Withdraw
        </Button>
      </div>
    </div>
  );
}

export default App;
