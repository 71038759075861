export function ellipsizeText({ text, max = 12, mode = 'middle', symbol = '…' }) {
  if (text == null || symbol == null) {
    return text;
  }

  if (text.length <= max || symbol.length >= max) {
    return text;
  }

  const over = max - symbol.length;
  const startAt = over / 2 + (over % 2);

  let result = text;
  switch (mode) {
    case 'head':
      result = `${symbol}${text.slice(text.length - over)}`;
      break;
    case 'middle':
      result = `${text.slice(0, startAt)}${symbol}${text.slice(startAt === over ? text.length : startAt - over)}`;
      break;
    case 'tail':
    default:
      result = `${text.slice(0, over)}${symbol}`;
      break;
  }
  return result;
}
