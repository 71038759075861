import { useCallback } from 'react';
import { WalletContext } from '@/context/wallet';
import { getNetwork } from '@/config';

function useSwitchNetwork() {
  const { provider } = WalletContext.useContainer();

  const switchNetwork = useCallback(
    async chainId => {
      try {
        const result = await provider.provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${chainId.toString(16)}` }],
        });
        return result;
      } catch (err) {
        console.log(err);
        const { params } = getNetwork(chainId);
        const result = await provider.provider.request({
          method: 'wallet_addEthereumChain',
          params: params,
        });
        return result;
      }
    },
    [provider]
  );
  return { switchNetwork };
}

export default useSwitchNetwork;
