import { useCallback, useEffect, useState } from 'react';
import { ethers } from 'ethers';

import useContract from './useContract';
// import ERC20ABI from '@/abi/ERC20.json';
import VaultABI from '@/abi/Vault.json';
import AavePoolABI from '@/abi/aave/Pool.json';
import AaveOracleABI from '@/abi/aave/Oracle.json';
import LidoAaveMonitorABI from '@/abi/monitor/LidoAaveMonitor.json';
import LidoAaveLeverageABI from '@/abi/LidoAaveLeverage.json';
import { WalletContext } from '@/context/wallet';
import useGasLimit from './useGasLimit';
import toast from '@/utils/toast';
import useGelato from './useGelato';

function useLidoAaveLeverage(props) {
  const { logic, monitor } = props ?? {};

  const { provider, account } = WalletContext.useContainer();

  const { getContract } = useContract();
  const { calcGasLimit } = useGasLimit();
  const { createTask, getDedicatedMsgSender } = useGelato();

  const [vault, setVault] = useState();
  const [token, setToken] = useState();
  const [amount, setAmount] = useState('');
  const [healthFactor, setHealthFactor] = useState('');
  const [maxCycles, setMaxCycles] = useState('');
  const [helper, setHelper] = useState();
  const [riskFactor, setRiskFactor] = useState('');
  const [aavePoolBalance, setAavePoolBalance] = useState();
  const [curvePoolBalance, setCurvePoolBalance] = useState();
  const [liquidityETH, setLiquidityETH] = useState('');
  const [liquidityWstETH, setLiquidityWstETH] = useState('');
  const [robot, setRobot] = useState('0x5Ca91327dF003aFA2207b374D5f19730CCa67e2C');

  const decimals = 18;
  const [vaultBalance, setVaultBalance] = useState();
  const [price, setPrice] = useState();
  const [position, setPosition] = useState();
  const [executor, setExecutor] = useState();

  const getBalance = useCallback(async () => {
    setVaultBalance(null);
    if (provider == null || account == null || vault == null) {
      return;
    }
    try {
      const balance = await provider.getBalance(vault);
      setVaultBalance(ethers.utils.formatEther(balance));
    } catch (err) {
      console.log(err);
    }
  }, [provider, account, vault]);

  const getPool = useCallback(async () => {
    const lido = getContract(logic, LidoAaveLeverageABI);
    if (lido == null) {
      return;
    }
    const poolAddress = await lido.pool();
    const pool = getContract(poolAddress, AavePoolABI);
    return pool;
  }, [getContract, logic]);

  const getHelper = useCallback(async () => {
    try {
      const lido = getContract(logic, LidoAaveLeverageABI);
      if (lido == null) {
        return;
      }
      const result = await lido.flashLoanHelper();
      setHelper(result);
    } catch (err) {
      console.log(err);
    }
  }, [getContract, logic]);

  const getPosition = useCallback(async () => {
    setPosition(null);
    const pool = await getPool();
    if (vault == null || pool == null) {
      return;
    }
    try {
      const logicContract = getContract(logic, LidoAaveLeverageABI);
      const oracle = await logicContract.oracle();
      const WETH = await logicContract.WETH();
      const oracleContract = getContract(oracle, AaveOracleABI);
      const price = await oracleContract.getAssetPrice(WETH);
      setPrice(price);
      const result = await pool.getUserAccountData(vault);
      setPosition(result);
    } catch (err) {
      console.log(err);
    }
  }, [getPool, getContract, logic, vault]);

  const enter = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const _amount = ethers.utils.parseUnits(amount, decimals);
      const _vaultBalance = ethers.utils.parseUnits(vaultBalance, decimals);
      if (_amount.gt(_vaultBalance)) {
        throw new Error('amount exceeds vault balance');
      }
      const _healthFactor = ethers.utils.parseEther(healthFactor);
      if (_healthFactor.lte(ethers.utils.parseEther('1'))) {
        throw new Error('health factor ≤ 1');
      }
      let _maxCycles = 0;
      if (!maxCycles?.match(/^[0-9]+$/)) {
        throw new Error('max cycles is not a number');
      } else {
        _maxCycles = parseInt(maxCycles);
      }
      const iface = new ethers.utils.Interface(LidoAaveLeverageABI);
      const args = iface.encodeFunctionData('enter', [_amount, _healthFactor, _maxCycles]);
      const gasLimit = await contract.estimateGas.execute(logic, args);
      const tx = await contract.execute(logic, args, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      getBalance();
      getPosition();
      setAmount('');
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [
    getContract,
    vault,
    logic,
    amount,
    healthFactor,
    maxCycles,
    vaultBalance,
    decimals,
    calcGasLimit,
    getBalance,
    getPosition,
  ]);

  const getAavePoolBalance = useCallback(async () => {
    const lidoAaveContract = getContract(logic, LidoAaveLeverageABI);
    if (lidoAaveContract == null) {
      return;
    }
    const wstETH = await lidoAaveContract.wstETH();
    const monitorContract = getContract(monitor[0], LidoAaveMonitorABI);
    if (monitorContract == null) {
      return;
    }
    try {
      const result = await monitorContract.getAaveMarketBorrowRateAndLiquidity(wstETH);
      setAavePoolBalance(ethers.utils.formatEther(result[1]));
    } catch (err) {
      console.log(err);
    }
  }, [getContract, logic, monitor]);

  const getCurvePoolBalance = useCallback(async () => {
    const monitorContract = getContract(monitor[0], LidoAaveMonitorABI);
    if (monitorContract == null) {
      return;
    }
    try {
      const curvePool = await monitorContract.curveStEthBaseEthPool();
      const result = await monitorContract.getCurveEthLiquidity(curvePool);
      setCurvePoolBalance(ethers.utils.formatEther(result));
    } catch (err) {
      console.log(err);
    }
  }, [getContract, monitor]);

  const exit = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const iface = new ethers.utils.Interface(LidoAaveLeverageABI);
      const args = iface.encodeFunctionData('exit');
      const gasLimit = await contract.estimateGas.execute(logic, args);
      const tx = await contract.execute(logic, args, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      getBalance();
      getPosition();
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, logic, calcGasLimit, getBalance, getPosition]);

  const getExecutor = useCallback(async () => {
    try {
      const sender = await getDedicatedMsgSender();
      setExecutor(sender);
    } catch (err) {
      setExecutor();
    }
  }, [getDedicatedMsgSender]);

  const createExitTask = useCallback(async () => {
    try {
      const vaultFace = new ethers.utils.Interface(VaultABI);
      const selector = vaultFace.getSighash(vaultFace.getFunction('execute'));
      // resolver
      const resolverFace = new ethers.utils.Interface(LidoAaveMonitorABI);
      // address _vault,
      // uint256 _healthFactorThreshold,
      // uint256 _anchorEthPriceThreshold,
      // uint256 _ethCurveCashThreshold,
      // uint256 _wstEthCashThreshold,
      // uint128 _ethBorrowRateThreshold,
      // uint256 _priceDeviationRatio 10000
      const _riskFactor = ethers.utils.parseEther(riskFactor);
      if (_riskFactor.lte(ethers.utils.parseEther('1'))) {
        throw new Error('risk health factor ≤ 1');
      }

      const _liquidityWstETH = ethers.utils.parseEther(liquidityWstETH);
      const _liquidityETH = ethers.utils.parseEther(liquidityETH);

      const resolverData = resolverFace.encodeFunctionData(
        'checker(address,uint256,uint256,uint256,uint256,uint128,uint256)',
        [
          vault,
          _riskFactor,
          ethers.utils.parseEther('0.925'),
          _liquidityETH,
          _liquidityWstETH,
          ethers.utils.parseUnits('0.08', 27),
          1000,
        ]
      );
      const { tx } = await createTask({
        name: 'Lido Aave wstETH-ETH leverage',
        execAddress: vault,
        execSelector: selector,
        startTime: 0,
        useTreasury: true,
        dedicatedMsgSender: true,
        resolverAddress: monitor[0],
        resolverData: resolverData,
        // resolverAbi: JSON.stringify(counterAbi),
      });
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [createTask, vault, riskFactor, monitor, liquidityWstETH, liquidityETH]);

  const approveAutomate = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const authorized = await contract.hasImplementation(executor, logic);
      if (authorized) {
        throw new Error('authorized');
      }
      const gasLimit = await contract.estimateGas.grantImplementation(executor, logic);
      const tx = await contract.grantImplementation(executor, logic, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [calcGasLimit, executor, getContract, logic, vault]);

  const approve = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const authorized = await contract.hasImplementation(robot, logic);
      if (authorized) {
        throw new Error('authorized');
      }
      const gasLimit = await contract.estimateGas.grantImplementation(robot, logic);
      const tx = await contract.grantImplementation(robot, logic, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      setRobot('');
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, robot, logic, calcGasLimit]);

  const approveFlashLoan = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      if (helper == null) {
        throw new Error('helper is null');
      }
      const authorized = await contract.hasImplementation(helper, logic);
      if (authorized) {
        throw new Error('authorized');
      }
      const gasLimit = await contract.estimateGas.grantImplementation(helper, logic);
      const tx = await contract.grantImplementation(helper, logic, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, helper, logic, calcGasLimit]);

  useEffect(() => {
    getBalance();
  }, [getBalance]);

  useEffect(() => {
    getHelper();
  }, [getHelper]);

  useEffect(() => {
    getPosition();
  }, [getPosition]);

  useEffect(() => {
    getAavePoolBalance();
  }, [getAavePoolBalance]);

  useEffect(() => {
    getCurvePoolBalance();
  }, [getCurvePoolBalance]);

  useEffect(() => {
    getExecutor();
  }, [getExecutor]);

  return {
    vault,
    setVault,
    token,
    setToken,
    amount,
    setAmount,
    healthFactor,
    setHealthFactor,
    maxCycles,
    setMaxCycles,
    helper,
    riskFactor,
    setRiskFactor,
    aavePoolBalance,
    curvePoolBalance,
    liquidityETH,
    setLiquidityETH,
    liquidityWstETH,
    setLiquidityWstETH,
    robot,
    setRobot,
    vaultBalance,
    price,
    position,
    enter,
    exit,
    createExitTask,
    approveAutomate,
    executor,
    approve,
    approveFlashLoan,
  };
}

export default useLidoAaveLeverage;
