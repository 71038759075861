import { Fragment, useEffect, useState } from 'react';
// import classNames from 'classnames';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { ellipsizeText } from '@/utils/input';
import { getBlockExplorerUrl } from '@/config';
import { WalletContext } from '@/context/wallet';
import { ReactComponent as Link } from '@/assets/link.svg';

import useHistoryState from '@/hooks/useHistoryState';

function SelectToken(props) {
  const { className, onChange, tokenKey = 'tokenIndex', tokens = [] } = props;
  const { chainId } = WalletContext.useContainer();
  const blockExplorerUrl = getBlockExplorerUrl(chainId);

  const [select, setSelect] = useHistoryState(tokenKey, 0);
  const [token, setToken] = useState();

  useEffect(() => {
    setToken(tokens[select]);
    onChange?.(tokens[select]);
  }, [tokens, onChange, select]);

  return (
    <div className={className ?? 'mt-6'}>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="inline-flex w-full items-center justify-center rounded-md bg-slate-400 bg-opacity-30 px-4 py-2 text-base hover:bg-opacity-20 lg:text-xl">
          {token?.symbol}
          <ChevronDownIcon className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2 min-w-[96px] origin-top-right flex-col rounded-md bg-slate-600 py-3 shadow-lg focus:outline-none">
            {tokens.map((item, index) => (
              <Menu.Item key={index}>
                <div
                  className="flex cursor-pointer items-center justify-between px-5 py-2 hover:bg-slate-500"
                  onClick={() => {
                    setSelect(index);
                  }}
                >
                  <div className="text-sm font-medium">{item.symbol}</div>
                  <div className="ml-2 min-w-max text-xs">{ellipsizeText({ text: item.address })}</div>
                </div>
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
      {token?.address ? (
        <div className="mt-2 flex items-center gap-2 text-sm lg:text-base">
          <span>Contract:</span>
          <a
            href={`${blockExplorerUrl}/address/${token?.address}`}
            className="flex items-center gap-2 hover:opacity-70"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
              {ellipsizeText({ text: token?.address })}
            </span>
            <i className="w-4 opacity-30">
              <Link />
            </i>
          </a>
        </div>
      ) : null}
    </div>
  );
}

export default SelectToken;
