import Networks from './networks';
import Chains from './chains';
import Contracts from './contracts';
import { ETH as _ETH } from '@gelatonetwork/ops-sdk';

const CHAIN_IDS = [1, 10];

export const isNetworkSupport = chainId => CHAIN_IDS.includes(chainId);

export const getSupportNetworks = () =>
  Networks.filter(item => isNetworkSupport(parseInt(item.params[0]?.chainId, 16))) ?? [];

export const getNetwork = chainId => Networks.find(item => parseInt(item.params[0]?.chainId, 16) === chainId) ?? {};

export const getSupportChains = () =>
  Networks.filter(item => isNetworkSupport(parseInt(item.params[0]?.chainId, 16)))?.map(item => {
    const data = item.params[0];
    if (data == null) {
      return {};
    }
    const chainId = parseInt(data.chainId, 16);
    const chainInfo = Chains[chainId] ?? {};
    return {
      ...data,
      chainId,
      ...chainInfo,
    };
  }) ?? [];

export const getChainInfo = chainId => getSupportChains()?.filter(item => item.chainId === chainId)?.[0];

export const getBlockExplorerUrl = chainId => {
  let blockExplorerUrl;
  switch (chainId) {
    case 1:
      blockExplorerUrl = 'https://etherscan.io';
      break;
    case 5:
      blockExplorerUrl = 'https://goerli.etherscan.io';
      break;
    default:
      blockExplorerUrl = getChainInfo(chainId)?.blockExplorerUrls?.[0];
      break;
  }
  return blockExplorerUrl;
};

export const getContracts = chainId => Contracts[chainId] ?? {};

export const ETH = _ETH;

export const getExtension = (chainId, router) => {
  if (chainId == null) {
    return;
  }
  const extensions = getContracts(chainId)?.extensions ?? [];
  const extension = extensions.find(item => item.router === router);
  return extension;
};
