import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Tab } from '@headlessui/react';
import Button from '@/components/Button';
import { ellipsizeText } from '@/utils/input';
import useExtensions from '@/hooks/useExtensions';
import { VaultsContext } from '@/context/vaults';
import useHistoryState from '@/hooks/useHistoryState';
import useLoading from '@/hooks/useLoading';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

const Funds = props => {
  const navigate = useNavigate();
  return (
    <div
      className="mt-6 flex cursor-pointer items-center justify-between rounded-xl bg-black/90 p-4 md:mt-10 lg:p-6"
      onClick={() => {
        navigate('/automate');
      }}
    >
      <div>
        <div className="text-sm font-bold lg:text-lg">My automate</div>
        <p className="mt-2 text-xs opacity-70 lg:text-base">Automate is xxx</p>
      </div>
      <ChevronRightIcon className="h-8 w-8" />
    </div>
  );
};

const Vaults = props => {
  const { vaults } = VaultsContext.useContainer();
  const navigate = useNavigate();

  if (vaults == null || vaults.length === 0) {
    return <div className="rounded-xl bg-black/90 px-5 py-4 text-center lg:px-10 lg:py-8">No data</div>;
  }

  return (
    <div className="rounded-xl bg-black/90 px-5 py-4 lg:px-10 lg:py-8">
      {vaults.map((item, index) => {
        return (
          <div
            className="-mx-2 flex cursor-pointer items-center justify-between border-t border-white border-opacity-10 px-3 py-6 hover:bg-gray-400 hover:bg-opacity-10 md:flex-row lg:-mx-5"
            key={index}
            onClick={() => {
              navigate('/vault', { state: { address: item, name: `Vault ${index + 1}` } });
            }}
          >
            <div className="flex w-full flex-col gap-2">
              <div className="gap-3 text-xs font-bold lg:text-lg">
                <span>{`Vault ${index + 1}`}</span>
              </div>
              <div className="flex flex-col gap-1 text-xs md:flex-row md:items-center">
                Contract:
                <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
                  {ellipsizeText({ text: item })}
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const Extensions = props => {
  const { extensions } = useExtensions();

  const navigate = useNavigate();

  if (extensions == null || extensions.length === 0) {
    return <div className="rounded-xl bg-black/90 px-10 py-8 text-center">No data</div>;
  }

  return (
    <div className="rounded-xl bg-black/90 px-5 py-4 lg:px-10 lg:py-8">
      {extensions.map((item, index) => {
        return (
          <div
            className="-mx-2 flex cursor-pointer items-center justify-between border-t border-white border-opacity-10 px-3 py-6 hover:bg-gray-400 hover:bg-opacity-10 md:flex-row lg:-mx-5"
            key={index}
            onClick={() => {
              navigate(item.router, { state: { router: item.router } });
            }}
          >
            <div className="flex w-full flex-col gap-2">
              <div className="gap-3 text-xs font-bold lg:text-lg">
                <span>{item.name}</span>
              </div>
              <div className="flex flex-col gap-1 text-xs md:flex-row md:items-center">
                Contract:
                <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
                  {ellipsizeText({ text: item.address })}
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

function Home() {
  const tabs = [
    {
      id: 0,
      title: 'My Vaults',
      panel: <Vaults />,
    },
    {
      id: 1,
      title: 'Extensions',
      panel: <Extensions />,
    },
  ];

  const { createVault } = VaultsContext.useContainer();

  const { loading, fn } = useLoading(createVault);

  const [selectedIndex, setSelectedIndex] = useHistoryState('selectedIndex', 0);
  const handleTabChange = useCallback(
    index => {
      setSelectedIndex(index);
    },
    [setSelectedIndex]
  );

  return (
    <div>
      <h2 className="mt-2 text-base font-bold text-white lg:text-2xl">Automate your smart contracts with SmartSafe</h2>
      <Funds />
      <Tab.Group selectedIndex={selectedIndex} onChange={handleTabChange}>
        <div className="mt-6 flex items-center justify-between md:mt-10">
          <Tab.List className="flex space-x-4 rounded-xl bg-black/90 p-1.5 md:p-2">
            {tabs.map(item => (
              <Tab
                key={item.id}
                className={({ selected }) =>
                  classNames(
                    'w-24 rounded-md p-1.5 text-xs font-bold leading-5 focus:outline-none focus:ring-0 focus:ring-offset-0 md:w-28 md:p-2 lg:text-base',
                    selected ? 'bg-white/10 text-white' : 'text-white/50 hover:bg-white/10 hover:text-white'
                  )
                }
              >
                {item.title}
              </Tab>
            ))}
          </Tab.List>
          <Button
            className="bg-blue-500 bg-opacity-100 px-2 py-2 text-xs font-medium hover:bg-opacity-70 lg:px-5 lg:text-base"
            loading={loading}
            onClick={fn}
          >
            Create Vault
          </Button>
        </div>
        <Tab.Panels className="mt-6 md:mt-10">
          {tabs.map(item => (
            <Tab.Panel key={item.id}>{item.panel}</Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

export default Home;
