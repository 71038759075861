import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Menu, Transition } from '@headlessui/react';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { ellipsizeText } from '@/utils/input';
import { getBlockExplorerUrl } from '@/config';
import { WalletContext } from '@/context/wallet';
import { ReactComponent as Link } from '@/assets/link.svg';
import { VaultsContext } from '@/context/vaults';
import useHistoryState from '@/hooks/useHistoryState';
import Button from '../Button';
import useLoading from '@/hooks/useLoading';

function Vault(props) {
  const { className, onChange } = props;
  const navigate = useNavigate();
  const { chainId } = WalletContext.useContainer();
  const blockExplorerUrl = getBlockExplorerUrl(chainId);
  const { vaults, createVault } = VaultsContext.useContainer();

  const [select, setSelect] = useHistoryState('selectVault', 0);
  const [address, setAddress] = useState();

  useEffect(() => {
    setAddress(vaults[select]);
    onChange?.(vaults[select], select);
  }, [vaults, select, onChange]);

  const { loading, fn } = useLoading(createVault);

  if (vaults == null || vaults.length === 0) {
    return (
      <div className={classNames('relative mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-6', className)}>
        <h3 className="text-2xl font-bold">Vault</h3>
        <div className="mt-4">Don't have a vault, please creat one</div>
        <Button
          className="absolute top-1/2 right-2 -translate-y-1/2 transform md:right-6"
          loading={loading}
          onClick={fn}
        >
          Create Vault
        </Button>
      </div>
    );
  }

  return (
    <div className={classNames('relative mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-6', className)}>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="inline-flex w-full items-center justify-center rounded-md bg-slate-400 bg-opacity-30 px-4 py-2 text-base hover:bg-opacity-20 lg:text-xl">
          {`Vault ${select + 1}`}
          <ChevronDownIcon className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-slate-600 py-3 shadow-lg focus:outline-none">
            {vaults.map((item, index) => (
              <Menu.Item key={index}>
                <div
                  className="flex cursor-pointer items-center gap-2 px-5 py-2 hover:bg-slate-500"
                  onClick={() => {
                    setSelect(index);
                  }}
                >
                  <span className="text-sm font-medium">{`Vault ${index + 1}`}</span>
                  <span className="text-xs">{ellipsizeText({ text: item })}</span>
                </div>
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
      {/* <p>Don't have a smart contract vault</p> */}
      <div className="mt-4 flex items-center gap-2 text-sm lg:text-base">
        <span>Contract:</span>
        <a
          href={`${blockExplorerUrl}/address/${address}`}
          className="flex items-center gap-2 hover:opacity-70"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
            {ellipsizeText({ text: address })}
          </span>
          <i className="w-4 opacity-30">
            <Link />
          </i>
        </a>
      </div>
      <button
        className="absolute top-1/2 right-2 -translate-y-1/2 transform md:right-6"
        onClick={() => navigate('/vault', { state: { address, name: `Vault ${select + 1}` } })}
      >
        <ChevronRightIcon className="h-8 w-8" />
      </button>
    </div>
  );
}

export default Vault;
