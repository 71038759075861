import { useCallback } from 'react';
import Modal from '../Modal';
import { ReactComponent as MetaMask } from '@/assets/MetaMask.svg';
import { ReactComponent as WalletConnect } from '@/assets/WalletConnect.svg';
import { WalletContext } from '@/context/wallet';

function ConnectModal(props) {
  const { visible, onClose } = props;

  const { connect } = WalletContext.useContainer();

  const connectors = [
    { id: '1', name: 'MetaMask' },
    { id: '2', name: 'WalletConnect' },
  ];

  const data = {
    MetaMask: {
      icon: <MetaMask className="h-12 w-12" />,
    },
    WalletConnect: {
      icon: <WalletConnect className="h-12 w-12" />,
    },
  };

  const handConnect = useCallback(
    connector => {
      connect({ connector });
      onClose?.();
    },
    [connect, onClose]
  );

  return (
    <Modal visible={visible} onClose={onClose}>
      {connectors.map(connector => {
        return (
          <div
            key={connector.id}
            className="m-2 flex cursor-pointer flex-col items-center justify-center rounded-lg p-4 hover:bg-slate-900/90"
            onClick={() => handConnect(connector)}
          >
            {data[connector.name]?.icon}
            <div className="mt-4 text-2xl">{connector.name}</div>
          </div>
        );
      })}
    </Modal>
  );
}

export default ConnectModal;
