import { Routes, Route } from 'react-router-dom';
import classNames from 'classnames';
import { Toaster } from 'react-hot-toast';
import { WalletContext } from '@/context/wallet';

import './App.css';

import { VaultsContext } from '@/context/vaults';

import Header from '@/components/Header';
import Home from './home';
import Vault from './vault';
import AaveRevolving from './aave_revolving';
import IronBankSupply from './ironbank_supply';
import Automate from './automate';
import LidoAaveLeverage from './lido_aave_leverage';
import CompoundCometYield from './compound_comet_yield';
import CompoundCometYieldUSDC from './compound_comet_yield_usdc';
import ParaSpaceYield from './para_space_yield';
// import LidoSparkSavings from './lido_spark_savings';
import LidoSparkSavingsV2 from './lido_spark_savings_v2';
import LidoMakerDaoSavings from './lido_makerdao_savings';
import SparkSupply from './spark_supply';
import SparkBoost from './spark_boost';

function App() {
  return (
    <WalletContext.Provider>
      <div className={classNames('flex flex-col text-white', 'min-safe-h-screen')}>
        <Header />
        <main className="mx-auto w-full max-w-3xl p-4 md:p-8">
          <VaultsContext.Provider>
            <Routes>
              <Route path="/">
                <Route index element={<Home />} />
                <Route path="vault" element={<Vault />} />
                <Route path="aave_revolving" element={<AaveRevolving />} />
                <Route path="ironbank_supply" element={<IronBankSupply />} />
                <Route path="automate" element={<Automate />} />
                <Route path="lido_aave_leverage" element={<LidoAaveLeverage />} />
                <Route path="compound_comet_yield" element={<CompoundCometYield />} />
                <Route path="compound_comet_yield_usdc" element={<CompoundCometYieldUSDC />} />
                <Route path="para_space_yield" element={<ParaSpaceYield />} />
                {/* <Route path="lido_spark_savings" element={<LidoSparkSavings />} /> */}
                <Route path="lido_spark_savings_v2" element={<LidoSparkSavingsV2 />} />
                <Route path="lido_makerdao_savings" element={<LidoMakerDaoSavings />} />
                <Route path="spark_supply" element={<SparkSupply />} />
                <Route path="spark_boost" element={<SparkBoost />} />
              </Route>
            </Routes>
          </VaultsContext.Provider>
        </main>
        <Toaster />
        <div className="pointer-events-none fixed top-0 left-0 -z-[1] h-[200vh] w-[200vw] translate-x-[-50vw] translate-y-[-100vh] bg-gradient-radial from-slate-800 to-slate-900"></div>
      </div>
    </WalletContext.Provider>
  );
}

export default App;
