import classNames from 'classnames';
import { ReactComponent as Loading } from '@/assets/loading.svg';

function Button(props) {
  const { children, className, onClick, loading, disabled } = props;
  return (
    <button
      type="button"
      className={classNames(
        'inline-flex items-center justify-center gap-2 rounded-md bg-slate-400 bg-opacity-30 px-4 py-3 text-xs font-medium hover:bg-opacity-20 disabled:cursor-not-allowed disabled:opacity-75 md:text-sm',
        className
      )}
      onClick={onClick}
      disabled={loading || disabled}
    >
      {loading ? (
        <Loading className="mx-4 h-4 w-4 animate-spin fill-white/75 text-white/25 md:mx-5 md:h-5 md:w-5" />
      ) : (
        children
      )}
    </button>
  );
}

export default Button;
