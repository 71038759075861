export function getWalletName(defaultValue) {
  const value = localStorage.getItem('wallet_name');
  return value ? value : defaultValue;
}

export function setWalletName(value) {
  return localStorage.setItem('wallet_name', value);
}

export function removeWalletName() {
  return localStorage.removeItem('wallet_name');
}

export function removeWalletConnect() {
  return localStorage.removeItem('walletconnect');
}
