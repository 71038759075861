import { useCallback } from 'react';
import { ethers } from 'ethers';
import { WalletContext } from '@/context/wallet';

function useContract() {
  // const {} = props

  const { provider } = WalletContext.useContainer();
  const getContract = useCallback(
    (address, abi) => {
      if (address == null || provider == null) {
        return;
      }
      const signer = provider.getSigner();
      const contract = new ethers.Contract(address, abi, signer);
      return contract;
    },
    [provider]
  );

  return { getContract };
}

export default useContract;
