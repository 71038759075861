const Networks = [
  {
    params: [
      {
        chainId: '0x1',
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x5',
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x42',
        chainName: 'OKExChain Mainnet',
        nativeCurrency: {
          name: 'OKExChain Global Utility Token',
          symbol: 'OKT',
          decimals: 18,
        },
        rpcUrls: ['https://exchainrpc.okex.org'],
        blockExplorerUrls: ['https://www.oklink.com/en/okc'],
        // iconUrls: [],}
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x41',
        chainName: 'OKExChain Testnet',
        nativeCurrency: {
          name: 'OKExChain Global Utility Token in testnet',
          symbol: 'OKT',
          decimals: 18,
        },
        rpcUrls: ['https://exchaintestrpc.okex.org'],
        blockExplorerUrls: ['https://www.oklink.com/okexchain-test'],
        // iconUrls: [],}
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x38',
        chainName: 'Binance Smart Chain Mainnet',
        nativeCurrency: {
          name: 'Binance Chain Native Token',
          symbol: 'BNB',
          decimals: 18,
        },
        rpcUrls: [
          'https://bsc-dataseed1.binance.org',
          'https://bsc-dataseed2.binance.org',
          'https://bsc-dataseed3.binance.org',
          'https://bsc-dataseed4.binance.org',
          'https://bsc-dataseed1.defibit.io',
          'https://bsc-dataseed2.defibit.io',
          'https://bsc-dataseed3.defibit.io',
          'https://bsc-dataseed4.defibit.io',
          'https://bsc-dataseed1.ninicoin.io',
          'https://bsc-dataseed2.ninicoin.io',
          'https://bsc-dataseed3.ninicoin.io',
          'https://bsc-dataseed4.ninicoin.io',
          'wss://bsc-ws-node.nariox.org',
        ],
        blockExplorerUrls: ['https://bscscan.com'],
        // iconUrls: [],}
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x61',
        chainName: 'Binance Smart Chain Testnet',
        nativeCurrency: {
          name: 'Binance Chain Native Token',
          symbol: 'tBNB',
          decimals: 18,
        },
        rpcUrls: [
          'https://data-seed-prebsc-1-s1.binance.org:8545',
          'https://data-seed-prebsc-2-s1.binance.org:8545',
          'https://data-seed-prebsc-1-s2.binance.org:8545',
          'https://data-seed-prebsc-2-s2.binance.org:8545',
          'https://data-seed-prebsc-1-s3.binance.org:8545',
          'https://data-seed-prebsc-2-s3.binance.org:8545',
        ],
        blockExplorerUrls: ['https://testnet.bscscan.com'],
        // iconUrls: [],}
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x89',
        chainName: 'Matic Mainnet',
        nativeCurrency: {
          name: 'Matic',
          symbol: 'MATIC',
          decimals: 18,
        },
        rpcUrls: ['https://polygon-rpc.com', 'wss://ws-mainnet.matic.network'],
        blockExplorerUrls: ['https://polygonscan.com'],
        // iconUrls: [],}
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x13881',
        chainName: 'Matic Testnet Mumbai',
        nativeCurrency: {
          name: 'Matic',
          symbol: 'tMATIC',
          decimals: 18,
        },
        rpcUrls: ['https://rpc-mumbai.matic.today', 'wss://ws-mumbai.matic.today'],
        blockExplorerUrls: ['https://mumbai.polygonscan.com'],
        // iconUrls: [],}
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x80',
        chainName: 'Huobi ECO Chain Mainnet',
        nativeCurrency: {
          name: 'Huobi ECO Chain Native Token',
          symbol: 'HT',
          decimals: 18,
        },
        rpcUrls: ['https://http-mainnet.hecochain.com', 'wss://ws-mainnet.hecochain.com'],
        blockExplorerUrls: ['https://hecoinfo.com'],
        // iconUrls: [],}
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x100',
        chainName: 'Huobi ECO Chain Testnet',
        nativeCurrency: {
          name: 'Huobi ECO Chain Test Native Token',
          symbol: 'htt',
          decimals: 18,
        },
        rpcUrls: ['https://http-testnet.hecochain.com', 'wss://ws-testnet.hecochain.com'],
        blockExplorerUrls: ['https://testnet.hecoinfo.com'],
        // iconUrls: [],}
      },
    ],
  },
  {
    params: [
      {
        chainId: '0xa4b1',
        chainName: 'Arbitrum One',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'AETH',
          decimals: 18,
        },
        rpcUrls: ['https://arb1.arbitrum.io/rpc', 'https://arb1.arbitrum.io/ws'],
        blockExplorerUrls: ['https://arbiscan.io'],
        // iconUrls: [],}
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x45',
        chainName: 'Optimistic Ethereum Testnet Kovan',
        nativeCurrency: {
          name: 'Kovan Ether',
          symbol: 'KOR',
          decimals: 18,
        },
        rpcUrls: ['https://kovan.optimism.io/'],
        blockExplorerUrls: ['https://kovan-optimistic.etherscan.io'],
        // iconUrls: [],}
      },
    ],
  },
  {
    params: [
      {
        chainId: '0xa',
        chainName: 'Optimistic Ethereum',
        nativeCurrency: {
          name: 'Ether',
          symbol: 'OETH',
          decimals: 18,
        },
        rpcUrls: ['https://mainnet.optimism.io/'],
        blockExplorerUrls: ['https://optimistic.etherscan.io'],
        // iconUrls: [],}
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x507',
        chainName: 'Moonriver Alpha',
        nativeCurrency: {
          name: 'Dev',
          symbol: 'DEV',
          decimals: 18,
        },
        rpcUrls: ['https://rpc.testnet.moonbeam.network', 'wss://wss.testnet.moonbeam.network'],
        // blockExplorerUrls: [],
        // iconUrls: [],}
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x505',
        chainName: 'Moonriver',
        nativeCurrency: {
          name: 'Moonriver',
          symbol: 'MOVR',
          decimals: 18,
        },
        rpcUrls: ['https://rpc.moonriver.moonbeam.network', 'wss://wss.moonriver.moonbeam.network'],
        blockExplorerUrls: ['https://moonriver.moonscan.io'],
        // iconUrls: [],}
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x6357d2e0',
        chainName: 'Harmony Testnet Shard 0',
        nativeCurrency: {
          name: 'ONE',
          symbol: 'ONE',
          decimals: 18,
        },
        rpcUrls: ['https://api.s0.b.hmny.io'],
        blockExplorerUrls: ['https://explorer.pops.one'],
        // iconUrls: [],}
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x63564c40',
        chainName: 'Harmony Mainnet Shard 0',
        nativeCurrency: {
          name: 'ONE',
          symbol: 'ONE',
          decimals: 18,
        },
        rpcUrls: ['https://api.harmony.one'],
        blockExplorerUrls: ['https://explorer.harmony.one'],
        // iconUrls: [],}
      },
    ],
  },
  {
    params: [
      {
        chainId: '0xa515',
        chainName: 'Emerald Paratime Testnet',
        nativeCurrency: {
          name: 'Emerald Rose',
          symbol: 'ROSE',
          decimals: 18,
        },
        rpcUrls: ['https://testnet.emerald.oasis.dev/', 'wss://testnet.emerald.oasis.dev/ws'],
        blockExplorerUrls: ['https://testnet.explorer.emerald.oasis.dev'],
        // iconUrls: [],}
      },
    ],
  },
  {
    params: [
      {
        chainId: '0xa516',
        chainName: 'Emerald Paratime Mainnet',
        nativeCurrency: {
          name: 'Emerald Rose',
          symbol: 'ROSE',
          decimals: 18,
        },
        rpcUrls: ['https://emerald.oasis.dev', 'wss://emerald.oasis.dev/ws'],
        blockExplorerUrls: ['https://explorer.emerald.oasis.dev'],
        // iconUrls: [],}
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x1c',
        chainName: 'Boba rinkeby Testnet',
        nativeCurrency: {
          name: 'Boba',
          symbol: 'BOBA',
          decimals: 18,
        },
        rpcUrls: ['https://rinkeby.boba.network', 'wss://rinkeby.boba.network/ws'],
        blockExplorerUrls: ['https://blockexplorer.rinkeby.boba.network'],
        // iconUrls: [],}
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x4e454153',
        chainName: 'Aurora Testnet',
        nativeCurrency: {
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: ['https://testnet.aurora.dev/'],
        blockExplorerUrls: ['https://testnet.aurorascan.dev'],
        // iconUrls: [],}
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x4e454152',
        chainName: 'Aurora Mainnet',
        nativeCurrency: {
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: ['https://mainnet.aurora.dev'],
        blockExplorerUrls: ['https://aurorascan.dev'],
        // iconUrls: [],}
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x504',
        chainName: 'Moonbeam',
        nativeCurrency: {
          name: 'Glimmer',
          symbol: 'GLMR',
          decimals: 18,
        },
        rpcUrls: ['https://rpc.api.moonbeam.network', 'wss://wss.api.moonbeam.network'],
        blockExplorerUrls: ['https://moonbeam.moonscan.io'],
        // iconUrls: [],}
      },
    ],
  },
];

export default Networks;
