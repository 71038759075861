import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function useHistoryState(key, initialValue) {
  // const { key, initialValue } = props ?? {};
  const navigate = useNavigate();
  const location = useLocation();
  const [rawState, setRawState] = useState(() => {
    const value = location.state?.[key];
    return value ?? initialValue;
  });
  function setState(value) {
    navigate(location.pathname, { replace: true, state: { ...location.state, [key]: value } });
    setRawState(value);
  }
  return [rawState, setState];
}

export default useHistoryState;
