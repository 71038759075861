import { toast as _toast } from 'react-hot-toast';

function success(message, options) {
  _toast.success(message, options);
}

function error(message, options) {
  _toast.error(
    message,
    options ?? {
      style: {
        wordBreak: 'break-all',
      },
    }
  );
}

function custom(message, options) {
  toast.custom(message, options);
}

const toast = {
  success,
  error,
  custom,
};

export default toast;
