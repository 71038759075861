import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';
import { ReactComponent as Link } from '@/assets/link.svg';
import Back from '@/components/Back';
import Button from '@/components/Button';
import Input from '@/components/Input';
import Vault from '@/components/Vault';
import { getBlockExplorerUrl, getExtension } from '@/config';
import { WalletContext } from '@/context/wallet';
import { ellipsizeText } from '@/utils/input';
import useIronBankSupply from '@/hooks/useIronBankSupply';
import SelectToken from '@/components/SelectToken';
import useLoading from '@/hooks/useLoading';
import useAutomate from '@/hooks/useAutomate';
import { hasString } from '@/utils/helper';
import useSupportTokens from '@/hooks/useSupportTokens';

function App() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { router } = state ?? {};
  const { chainId } = WalletContext.useContainer();
  const { name, address, describe, swap, rewards, monitor, supportTokens } = getExtension(chainId, router) ?? {};
  const blockExplorerUrl = getBlockExplorerUrl(chainId);
  const {
    setToken,
    vault,
    setVault,
    setRewards,
    setSwapLogic,
    amount,
    setAmount,
    robot,
    setRobot,
    asset,
    setAsset,
    liquidity,
    setLiquidity,
    vaultBalance,
    stakedBalance,
    rewardsBalance,
    enter,
    exitAll,
    claim,
    approve,
    approveSwap,
    createClaimTask,
    // createSwapTask,
    createExitTask,
    approveAutomate,
    executor,
  } = useIronBankSupply({ logic: address, monitor });

  const { walletBalance, fundsAddress, fundsBalance, depositAmount, setDepositAmount, deposit } = useAutomate();

  const handleVaultChange = useCallback(
    address => {
      setVault(address);
    },
    [setVault]
  );

  useEffect(() => {
    setRewards(rewards);
  }, [setRewards, rewards]);

  useEffect(() => {
    setSwapLogic(swap);
  }, [setSwapLogic, swap]);

  const chainIdRef = useRef();
  useEffect(() => {
    if (chainIdRef.current == null) {
      chainIdRef.current = chainId;
    } else {
      if (chainIdRef.current !== chainId) {
        navigate('/', { replace: true });
      }
    }
  }, [chainId, navigate]);

  useEffect(() => {
    if (chainIdRef.current != null && address == null) {
      navigate('/', { replace: true });
    }
  }, [address, navigate]);

  const { tokens } = useSupportTokens({ supportTokens });

  const { loading: enterLoading, fn: enterFn } = useLoading(enter);
  const { loading: exitAllLoading, fn: exitAllFn } = useLoading(exitAll);
  const { loading: claimLoading, fn: claimFn } = useLoading(claim);
  const { loading: approveLoading, fn: approveFn } = useLoading(approve);
  const { loading: approveSwapLoading, fn: approveSwapFn } = useLoading(approveSwap);
  const { loading: depositAutomateFundsLoading, fn: depositAutomateFundsFn } = useLoading(deposit);
  const { loading: createClaimTaskLoading, fn: createClaimTaskFn } = useLoading(createClaimTask);
  // const { loading: createSwapTaskLoading, fn: createSwapTaskFn } = useLoading(createSwapTask);
  const { loading: createExitTaskLoading, fn: createExitTaskFn } = useLoading(createExitTask);
  const { loading: approveAutomateLoading, fn: approveAutomateFn } = useLoading(approveAutomate);

  return (
    <div>
      <Back />
      <div className="mt-5">
        <h1 className="text-2xl font-bold">{name}</h1>
        <div className="mt-2 flex items-center gap-2">
          <span>Contract:</span>
          <a
            href={`${blockExplorerUrl}/address/${address}`}
            className="flex items-center gap-2 hover:opacity-70"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
              {ellipsizeText({ text: address })}
            </span>
            <i className="w-4 opacity-30">
              <Link />
            </i>
          </a>
        </div>
        <p className="mt-2 opacity-30">{describe}</p>
      </div>
      <Vault onChange={handleVaultChange} />
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Enter</h3>
        <SelectToken
          tokens={tokens}
          onChange={token => {
            setToken(token?.address);
          }}
        />
        <p className="mt-2 text-sm text-white/70">{`Vault balance: ${vaultBalance ?? '--'}`}</p>
        <div className="mt-2 flex items-center justify-between gap-4">
          <Input
            onMax={() => {
              setAmount(vaultBalance ?? '');
            }}
            onChange={event => {
              setAmount(event.target.value);
            }}
            value={amount}
          />
          <Button
            className="min-w-[96px]"
            disabled={vault == null || !hasString(amount)}
            loading={enterLoading}
            onClick={enterFn}
          >
            Enter
          </Button>
        </div>
      </div>
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Exit</h3>
        <h4 className="mt-5 text-xl">Staked detail</h4>
        <div className="mt-5 w-full text-xs text-white/70 lg:text-sm">
          {stakedBalance?.map((item, index) => (
            <p className="mt-2" key={index}>{`${item.stakingTokenAddress} / ${item.balance}`}</p>
          ))}
        </div>
        <div className="mt-6 flex items-center gap-4">
          {/* <Button onClick={exit}>Exit</Button> */}
          <Button
            className="min-w-[96px]"
            disabled={vault == null || stakedBalance == null}
            loading={exitAllLoading}
            onClick={exitAllFn}
          >
            ExitAll
          </Button>
        </div>
      </div>
      {/* <p className="mt-2" key={index}>{`${item.rewadToken.rewadTokenAddress} / ${item.amount}`}</p> */}
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Claim</h3>
        <h4 className="mt-5 text-xl">Rewards detail</h4>
        <div className="mt-5 w-full text-xs text-white/70 lg:text-sm">
          {rewardsBalance?.map((item, index) => {
            return (
              <p className="mt-2" key={index}>{`${item[0]?.[0]} / ${ethers.utils.formatUnits(
                item[1],
                item[0]?.[2] ?? 18
              )} ${item[0]?.[1]}`}</p>
            );
          })}
        </div>
        <Button
          className="mt-6 min-w-[96px]"
          disabled={vault == null || rewardsBalance == null}
          loading={claimLoading}
          onClick={claimFn}
        >
          Claim
        </Button>
      </div>
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Automate (Beta)</h3>
        <h4 className="mt-5 text-xl">Gelato automate gas funds</h4>
        <div>
          <a
            className="mt-2 inline-flex items-center gap-2 text-xs hover:opacity-70"
            href={`${blockExplorerUrl}/address/${fundsAddress}`}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
              {ellipsizeText({ text: fundsAddress })}
            </span>
            <i className="w-4 opacity-30">
              <Link />
            </i>
          </a>
        </div>
        <p className="mt-2 text-sm text-white/70">{`Wallet balance: ${walletBalance ?? '--'}`}</p>
        <p className="mt-2 text-sm text-white/70">{`Gelato gas funds balance: ${fundsBalance ?? '--'}`}</p>
        <div className="mt-2 flex items-center justify-between gap-4">
          <Input
            onChange={event => {
              setDepositAmount(event.target.value);
            }}
            value={depositAmount}
            placeholder="gas amount"
          />
          <Button
            className="min-w-[96px]"
            disabled={vault == null || !hasString(depositAmount)}
            loading={depositAutomateFundsLoading}
            onClick={depositAutomateFundsFn}
          >
            Deposit
          </Button>
        </div>
        <p className="mt-5 text-base lg:text-lg">Create a task to auto claim</p>
        <Button
          className="mt-2 min-w-[96px]"
          disabled={vault == null}
          loading={createClaimTaskLoading}
          onClick={createClaimTaskFn}
        >
          Create
        </Button>
        {/* <p className="mt-5 text-base lg:text-lg">Create a task to auto swap rewards</p>
        <Button
          className="mt-2 min-w-[96px]"
          disabled={vault == null}
          loading={createSwapTaskLoading}
          onClick={createSwapTaskFn}
        >
          Create
        </Button> */}
        <p className="mt-5 text-base lg:text-lg">Create a task to auto exit</p>
        <p className="mt-2 text-xs lg:text-sm">Select a asset to exit</p>
        <SelectToken
          className="mt-2"
          tokens={tokens}
          onChange={token => {
            setAsset(token);
          }}
        />
        <p className="mt-2 text-xs lg:text-sm">Ironbank asset min liquidity to exit</p>
        <Input
          className="mt-2"
          placeholder="min liquidity"
          onChange={event => {
            setLiquidity(event.target.value);
          }}
          value={liquidity}
        />
        <Button
          className="mt-2 min-w-[96px]"
          disabled={vault == null || asset == null || !hasString(liquidity)}
          loading={createExitTaskLoading}
          onClick={createExitTaskFn}
        >
          Create
        </Button>
        <p className="mt-2 text-base lg:text-lg">{`Approve a executor to execute the tasks`}</p>
        <div>
          <a
            className="mt-2 inline-flex items-center gap-2 text-xs hover:opacity-70"
            href={`${blockExplorerUrl}/address/${executor}`}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
              {ellipsizeText({ text: executor })}
            </span>
            <i className="w-4 opacity-30">
              <Link />
            </i>
          </a>
        </div>
        <Button
          className="mt-2 min-w-[96px]"
          disabled={vault == null || executor == null}
          loading={approveAutomateLoading}
          onClick={approveAutomateFn}
        >
          Approve
        </Button>
      </div>
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Robot</h3>
        <p className="mt-6 text-sm text-white/70">Approve a robot to auto claim and exit</p>
        <div className="mt-4 flex items-center justify-between gap-4">
          <Input
            onChange={event => {
              setRobot(event.target.value);
            }}
            value={robot}
          />
          <Button className="min-w-[96px]" disabled={vault == null} loading={approveLoading} onClick={approveFn}>
            Approve
          </Button>
        </div>
        {swap == null ? null : (
          <>
            <p className="mt-6 text-sm text-white/70">Approve a robot to auto swap rewards</p>
            <p className="mt-2 flex items-center gap-2 text-sm text-white/70">
              <span>Swap contract:</span>
              <a
                href={`${blockExplorerUrl}/address/${swap}`}
                className="flex items-center gap-2 hover:opacity-70"
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
                  {ellipsizeText({ text: swap })}
                </span>
                <i className="w-4 opacity-30">
                  <Link />
                </i>
              </a>
            </p>
            <div className="mt-4 flex items-center justify-between gap-4">
              <Input
                onChange={event => {
                  setRobot(event.target.value);
                }}
                value={robot}
              />
              <Button
                className="min-w-[96px]"
                disabled={vault == null}
                loading={approveSwapLoading}
                onClick={approveSwapFn}
              >
                Approve
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default App;
