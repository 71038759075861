import { useCallback, useEffect, useState } from 'react';
import { WalletContext } from '@/context/wallet';
import { getContracts } from '@/config';

function useExtensions() {
  const { chainId } = WalletContext.useContainer();

  const [extensions, setExtensions] = useState([]);

  const getExtensions = useCallback(() => {
    setExtensions(getContracts(chainId).extensions ?? []);
  }, [chainId]);

  useEffect(() => {
    getExtensions();
  }, [getExtensions]);

  return { extensions };
}

export default useExtensions;
