import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';
import { ReactComponent as Link } from '@/assets/link.svg';
import Back from '@/components/Back';
import Button from '@/components/Button';
import Input from '@/components/Input';
import Vault from '@/components/Vault';
import { getBlockExplorerUrl, getExtension } from '@/config';
import { WalletContext } from '@/context/wallet';
import { ellipsizeText } from '@/utils/input';
import useLidoSparkSavings from '@/hooks/useLidoSparkSavings';
import SelectToken from '@/components/SelectToken';
import useLoading from '@/hooks/useLoading';
import useAutomate from '@/hooks/useAutomate';
import { formatPercentages, formatNumber } from '@/utils/format';
import { hasString } from '@/utils/helper';
import useSupportTokens from '@/hooks/useSupportTokens';

function App() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { router } = state ?? {};
  const { chainId } = WalletContext.useContainer();
  const { name, address, describe, monitor, supportTokens } = getExtension(chainId, router) ?? {};
  const blockExplorerUrl = getBlockExplorerUrl(chainId);
  const {
    vault,
    setVault,
    setToken,
    amount,
    setAmount,
    healthFactor,
    setHealthFactor,
    rebalanceHealthFactor,
    setRebalanceHealthFactor,
    riskFactor,
    setRiskFactor,
    daiPriceThreshold,
    setDaiPriceThreshold,
    stETHPriceThreshold,
    setStEThPriceThreshold,
    liquidityWstETH,
    setLiquidityWstETH,
    daiBorrowRateThreshold,
    setDaiBorrowRateThreshold,
    targetFactor,
    setTargetFactor,
    minFactor,
    setMinFactor,
    maxFactor,
    setMaxFactor,
    robot,
    setRobot,
    vaultBalance,
    position,
    poolWstETHBalance,
    enter,
    exit,
    rebalance,
    createExitTask,
    createRebalanceTask,
    approveAutomate,
    executor,
    approve,
  } = useLidoSparkSavings({ logic: address, monitor });

  const { walletBalance, fundsAddress, fundsBalance, depositAmount, setDepositAmount, deposit } = useAutomate();

  const handleVaultChange = useCallback(
    address => {
      setVault(address);
    },
    [setVault]
  );

  const chainIdRef = useRef();
  useEffect(() => {
    if (chainIdRef.current == null) {
      chainIdRef.current = chainId;
    } else {
      if (chainIdRef.current !== chainId) {
        navigate('/', { replace: true });
      }
    }
  }, [chainId, navigate]);

  useEffect(() => {
    if (chainIdRef.current != null && address == null) {
      navigate('/', { replace: true });
    }
  }, [address, navigate]);

  const { loading: enterLoading, fn: enterFn } = useLoading(enter);
  const { loading: exitLoading, fn: exitFn } = useLoading(exit);
  const { loading: rebalanceLoading, fn: rebalanceFn } = useLoading(rebalance);
  const { loading: depositAutomateFundsLoading, fn: depositAutomateFundsFn } = useLoading(deposit);
  const { loading: createExitTaskLoading, fn: createExitTaskFn } = useLoading(createExitTask);
  const { loading: createRebalanceTaskLoading, fn: createRebalanceTaskFn } = useLoading(createRebalanceTask);
  const { loading: approveAutomateLoading, fn: approveAutomateFn } = useLoading(approveAutomate);
  const { loading: approveLoading, fn: approveFn } = useLoading(approve);

  const { tokens } = useSupportTokens({ supportTokens });

  const currentHealthFactor = position
    ? ethers.constants.MaxUint256.eq(position.healthFactor)
      ? 'MaxUint256'
      : formatNumber(ethers.utils.formatEther(position.healthFactor))
    : undefined;

  return (
    <div>
      <Back />
      <div className="mt-5">
        <h1 className="text-2xl font-bold">{name}</h1>
        <div className="mt-2 flex items-center gap-2">
          <span>Contract:</span>
          <a
            href={`${blockExplorerUrl}/address/${address}`}
            className="flex items-center gap-2 hover:opacity-70"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
              {ellipsizeText({ text: address })}
            </span>
            <i className="w-4 opacity-30">
              <Link />
            </i>
          </a>
        </div>
        <p className="mt-2 opacity-30">{describe}</p>
      </div>
      <Vault onChange={handleVaultChange} />
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Enter</h3>
        <SelectToken
          tokens={tokens}
          onChange={token => {
            setToken(token?.address);
          }}
        />
        <p className="mt-2 text-sm text-white/70">{`Vault balance: ${vaultBalance ?? '--'}`}</p>
        <div className="mt-2">
          <Input
            placeholder="amount"
            onMax={() => {
              setAmount(vaultBalance ?? '');
            }}
            onChange={event => {
              setAmount(event.target.value);
            }}
            value={amount}
          />
          <Input
            className="mt-4"
            placeholder="health factor, suggest bigger than 1.161 (0.795/0.685)"
            onChange={event => {
              setHealthFactor(event.target.value);
            }}
            value={healthFactor}
          />
          <Button
            className="mt-4 min-w-[96px]"
            disabled={vault == null || !hasString(amount) || !hasString(healthFactor)}
            loading={enterLoading}
            onClick={enterFn}
          >
            Enter
          </Button>
        </div>
      </div>
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Exit</h3>
        <h4 className="mt-5 text-xl">Position detail</h4>
        {position ? (
          <div className="mt-5 w-full text-xs text-white/70 lg:text-sm">
            <p className="mt-2">{`totalCollateralBase: ${formatNumber(
              ethers.utils.formatUnits(position.totalCollateralBase, 8)
            )}`}</p>
            <p className="mt-2">{`totalDebtBase: ${formatNumber(
              ethers.utils.formatUnits(position.totalDebtBase, 8)
            )}`}</p>
            <p className="mt-2">{`availableBorrowsBase: ${formatNumber(
              ethers.utils.formatUnits(position.availableBorrowsBase, 8)
            )}`}</p>
            <p className="mt-2">{`currentLiquidationThreshold: ${formatPercentages(
              ethers.utils.formatUnits(position.currentLiquidationThreshold, 4)
            )}`}</p>
            <p className="mt-2">{`ltv: ${formatPercentages(ethers.utils.formatUnits(position.ltv, 4))}`}</p>
            <p className="mt-2">{`healthFactor: ${currentHealthFactor}`}</p>
            <p className="mt-2">{`wstETH supply balance: ${formatNumber(
              ethers.utils.formatUnits(position.supplyAmount, position.supplyTokenDecimals)
            )}`}</p>
            <p className="mt-2">{`DAI borrow balance: ${formatNumber(
              ethers.utils.formatUnits(position.borrowAmount, position.borrowTokenDecimals)
            )}`}</p>
            <p className="mt-2">{`DAI redeem balance: ${formatNumber(
              ethers.utils.formatUnits(position.redeemAmount, position.borrowTokenDecimals)
            )}`}</p>
          </div>
        ) : null}
        <div className="mt-6 flex items-center gap-4">
          <Button
            className="min-w-[96px]"
            disabled={vault == null || position == null}
            loading={exitLoading}
            onClick={exitFn}
          >
            Exit
          </Button>
        </div>
      </div>
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Rebalance</h3>
        <h4 className="mt-5 text-xl">rebalance health factor by increase debt or reduce debt</h4>
        {position ? (
          <div className="mt-5 w-full text-xs text-white/70 lg:text-sm">
            <p className="mt-2">{`healthFactor: ${currentHealthFactor}`}</p>{' '}
          </div>
        ) : null}
        <div className="mt-6 flex items-center gap-4">
          <Input
            placeholder="health factor, suggest bigger than 1.161 (0.795/0.685)"
            onChange={event => {
              setRebalanceHealthFactor(event.target.value);
            }}
            value={rebalanceHealthFactor}
          />
          <Button
            className="min-w-[96px]"
            disabled={vault == null || position == null || !hasString(rebalanceHealthFactor)}
            loading={rebalanceLoading}
            onClick={rebalanceFn}
          >
            Rebalance
          </Button>
        </div>
      </div>
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Automate (Beta)</h3>
        <h4 className="mt-5 text-xl">Gelato automate gas funds</h4>
        <div>
          <a
            className="mt-2 inline-flex items-center gap-2 text-xs hover:opacity-70"
            href={`${blockExplorerUrl}/address/${fundsAddress}`}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
              {ellipsizeText({ text: fundsAddress })}
            </span>
            <i className="w-4 opacity-30">
              <Link />
            </i>
          </a>
        </div>
        <p className="mt-2 text-sm text-white/70">{`Wallet balance: ${walletBalance ?? '--'}`}</p>
        <p className="mt-2 text-sm text-white/70">{`Gelato gas funds balance: ${fundsBalance ?? '--'}`}</p>
        <div className="mt-2 flex items-center justify-between gap-4">
          <Input
            onChange={event => {
              setDepositAmount(event.target.value);
            }}
            value={depositAmount}
            placeholder="gas amount"
          />
          <Button
            className="min-w-[96px]"
            disabled={vault == null || !hasString(depositAmount)}
            loading={depositAutomateFundsLoading}
            onClick={depositAutomateFundsFn}
          >
            Deposit
          </Button>
        </div>
        <p className="mt-5 text-base lg:text-lg">Create a task to auto exit</p>
        <p className="mt-2 text-xs lg:text-sm">
          {currentHealthFactor
            ? `Aave min health factor to exit, current healthFactor is ${currentHealthFactor}`
            : 'Aave min health factor to exit'}
        </p>
        <Input
          className="mt-2"
          placeholder="risk health factor"
          onChange={event => {
            setRiskFactor(event.target.value);
          }}
          value={riskFactor}
        />
        <p className="mt-2 text-xs lg:text-sm">anchor DAI price threshold</p>
        <Input
          className="mt-2"
          placeholder="min DAI/USD price (e.g. 0.95)"
          onChange={event => {
            setDaiPriceThreshold(event.target.value);
          }}
          value={daiPriceThreshold}
        />
        <p className="mt-2 text-xs lg:text-sm">anchor stETH price threshold</p>
        <Input
          className="mt-2"
          placeholder="min stETH/ETH price (e.g. 0.95)"
          onChange={event => {
            setStEThPriceThreshold(event.target.value);
          }}
          value={stETHPriceThreshold}
        />
        <p className="mt-4 text-xs lg:text-sm">
          {poolWstETHBalance
            ? `Aave wstETH min liquidity to exit, current cash is ${poolWstETHBalance}`
            : 'Aave wstETH min liquidity to exit'}
        </p>
        <Input
          className="mt-2"
          placeholder="min liquidity of wstETH"
          onChange={event => {
            setLiquidityWstETH(event.target.value);
          }}
          value={liquidityWstETH}
        />
        <p className="mt-2 text-xs lg:text-sm">DAI borrow rate threshold</p>
        <Input
          className="mt-2"
          placeholder="max DAI borrow rate (e.g. 0.05)"
          onChange={event => {
            setDaiBorrowRateThreshold(event.target.value);
          }}
          value={daiBorrowRateThreshold}
        />
        <Button
          className="mt-2 min-w-[96px]"
          disabled={
            vault == null ||
            !hasString(riskFactor) ||
            !hasString(daiPriceThreshold) ||
            !hasString(stETHPriceThreshold) ||
            !hasString(liquidityWstETH) ||
            !hasString(daiBorrowRateThreshold)
          }
          loading={createExitTaskLoading}
          onClick={createExitTaskFn}
        >
          Create
        </Button>
        <p className="mt-5 text-base lg:text-lg">Create a task to auto rebalance</p>
        <p className="mt-2 text-xs lg:text-sm">target health factor</p>
        <Input
          className="mt-2"
          placeholder="target health factor (e.g. 1.25)"
          onChange={event => {
            setTargetFactor(event.target.value);
          }}
          value={targetFactor}
        />
        <p className="mt-2 text-xs lg:text-sm">
          min health factor, when health factor is less than this, auto rebalance to target health factor
        </p>
        <Input
          className="mt-2"
          placeholder="min health factor (e.g. 1.20)"
          onChange={event => {
            setMinFactor(event.target.value);
          }}
          value={minFactor}
        />
        <p className="mt-2 text-xs lg:text-sm">
          max health factor, when health factor is greater than this, auto rebalance to target health factor
        </p>
        <Input
          className="mt-2"
          placeholder="max health factor (e.g. 1.30)"
          onChange={event => {
            setMaxFactor(event.target.value);
          }}
          value={maxFactor}
        />
        <Button
          className="mt-2 min-w-[96px]"
          disabled={vault == null || !hasString(targetFactor) || !hasString(minFactor) || !hasString(maxFactor)}
          loading={createRebalanceTaskLoading}
          onClick={createRebalanceTaskFn}
        >
          Create
        </Button>
        <p className="mt-2 text-base lg:text-lg">{`Approve a executor to execute the tasks`}</p>
        <div>
          <a
            className="mt-2 inline-flex items-center gap-2 text-xs hover:opacity-70"
            href={`${blockExplorerUrl}/address/${executor}`}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
              {ellipsizeText({ text: executor })}
            </span>
            <i className="w-4 opacity-30">
              <Link />
            </i>
          </a>
        </div>
        <Button
          className="mt-2 min-w-[96px]"
          disabled={vault == null || executor == null}
          loading={approveAutomateLoading}
          onClick={approveAutomateFn}
        >
          Approve
        </Button>
      </div>
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Robot</h3>
        <p className="mt-6 text-sm text-white/70">Approve a robot to auto exit</p>
        <div className="mt-4 flex items-center justify-between gap-4">
          <Input
            onChange={event => {
              setRobot(event.target.value);
            }}
            value={robot}
          />
          <Button className="min-w-[96px]" disabled={vault == null} loading={approveLoading} onClick={approveFn}>
            Approve
          </Button>
        </div>
      </div>
    </div>
  );
}

export default App;
