import { useCallback, useEffect, useRef, useState } from 'react';
import { ethers } from 'ethers';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { createContainer } from '../tools';
import { getWalletName, removeWalletConnect, removeWalletName, setWalletName } from '@/utils/store';

const useWallet = props => {
  // const { } = props;

  const ethereumRef = useRef();
  const walletNameRef = useRef();
  const [provider, setProvider] = useState();
  const [account, setAccount] = useState();
  const [chainId, setChainId] = useState();
  const [isConnected, setIsConnected] = useState();

  const disconnect = useCallback(async () => {
    try {
      if (ethereumRef.current?.isConnected()) {
        await ethereumRef.current.disconnect();
      }
    } catch (err) {
      console.log(err);
    }
    setIsConnected(false);
    removeWalletName();
    removeWalletConnect();
  }, []);

  const handleConnect = async ({ chainId }) => {
    // console.log(parseInt(chainId, 16));
    setChainId(parseInt(chainId, 16));
    setIsConnected(true);
    setWalletName(walletNameRef.current);
  };
  const handleDisconnect = error => {
    // console.log(error);
    setIsConnected(false);
    removeWalletName();
    removeWalletConnect();
    // window.location.reload();
  };
  const handleChainChanged = chainId => {
    // console.log(parseInt(chainId, 16));
    setChainId(parseInt(chainId, 16));
    const provider = new ethers.providers.Web3Provider(ethereumRef.current);
    setProvider(provider);
  };
  const handleAccountsChanged = accounts => {
    // console.log(accounts);
    setAccount(accounts[0]);
  };

  const metaMask = useCallback(async () => {
    const ethereum = window.ethereum;
    ethereumRef.current = ethereum;
    if (ethereum?.on) {
      ethereum.on('connect', handleConnect);
      ethereum.on('disconnect', handleDisconnect);
      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);
    }
    const accounts = await ethereum.request({
      method: 'eth_requestAccounts',
    });
    setAccount(accounts[0]);
    setIsConnected(true);
    const provider = new ethers.providers.Web3Provider(ethereum);
    setProvider(provider);
    const network = await provider.getNetwork();
    setChainId(network.chainId);
    setWalletName('MetaMask');
  }, []);

  const walletConnect = useCallback(async () => {
    const provider = new WalletConnectProvider();
    ethereumRef.current = provider;
    if (provider?.on) {
      provider.on('connect', handleConnect);
      provider.on('disconnect', handleDisconnect);
      provider.on('chainChanged', handleChainChanged);
      provider.on('accountsChanged', handleAccountsChanged);
    }
    await provider.enable();
    const web3Provider = new ethers.providers.Web3Provider(provider);
    setProvider(web3Provider);
    const accounts = await web3Provider.listAccounts();
    setAccount(accounts[0]);
    setIsConnected(true);
    const network = await provider.getNetwork();
    setChainId(network.chainId);
    setWalletName('WalletConnect');
  }, []);

  const connect = useCallback(
    connector => {
      switch (connector) {
        case 'MetaMask':
          metaMask();
          break;
        case 'WalletConnect':
          walletConnect();
          break;
        default:
          metaMask();
          break;
      }
    },
    [metaMask, walletConnect]
  );

  useEffect(() => {
    const walletName = getWalletName();
    if (walletName != null) {
      walletNameRef.current = walletName;
      connect(walletName);
    }
    return () => {
      if (ethereumRef.current?.removeListener) {
        ethereumRef.current.removeListener('connect', handleConnect);
        ethereumRef.current.removeListener('disconnect', handleDisconnect);
        ethereumRef.current.removeListener('chainChanged', handleChainChanged);
        ethereumRef.current.removeListener('accountsChanged', handleAccountsChanged);
      }
      ethereumRef.current = null;
    };
  }, [connect]);

  return { connect, provider, account, chainId, isConnected, disconnect };
};

export const WalletContext = createContainer(useWallet);
