import classNames from 'classnames';

function Input(props) {
  const { className, type, placeholder, value, onChange, onMax } = props;

  return (
    <div className={classNames('relative w-full', className)}>
      <input
        value={value}
        type={type ?? 'text'}
        placeholder={placeholder}
        onChange={onChange}
        className={classNames(
          'w-full rounded-md border-gray-700 bg-gray-800 py-2.5 text-sm shadow-sm md:text-base',
          onMax ? 'pr-14' : ''
        )}
      />
      {onMax ? (
        <span className="absolute inset-y-0 right-0 grid w-14 place-content-center">
          <button type="button" className="p-0.5 text-white/50 hover:text-white/70" onClick={onMax}>
            max
          </button>
        </span>
      ) : null}
    </div>
  );
}

export default Input;
