import { useState, useEffect, Fragment, useCallback } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
// import jazzicon from '@metamask/jazzicon';

import Button from '../Button';
import ConnectModal from '../ConnectModal';
import AccountModal from '../AccountModal';

import { ellipsizeText } from '@/utils/input';
import { getSupportChains, getChainInfo } from '@/config';
import { WalletContext } from '@/context/wallet';
import useSwitchNetwork from '@/hooks/useSwitchNetwork';

function Header() {
  const { switchNetwork } = useSwitchNetwork();

  const { chainId, account, isConnected } = WalletContext.useContainer();

  const handleSwitch = useCallback(
    _chainId => {
      if (_chainId !== chainId) {
        try {
          switchNetwork(_chainId);
        } catch (err) {
          console.log(err);
        }
      }
    },
    [chainId, switchNetwork]
  );

  const [connectVisible, setConnectVisible] = useState(false);
  const [accountVisible, setAccountVisible] = useState(false);

  const chains = getSupportChains();

  const [offset, setOffset] = useState(0);
  useEffect(() => {
    const onScroll = () => setOffset((window.pageYOffset > 80 ? 1 : window.pageYOffset / 80) * 1.5);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const style = {
    backgroundColor: `rgba(17, 24, 39, ${offset})`,
  };

  return (
    <header className="sticky top-0 z-30 border-b border-slate-800 px-4 py-4 md:px-8 lg:border-b-0" style={style}>
      <div className="mx-auto flex max-w-7xl items-center justify-between">
        <div className="flex items-center justify-between gap-4">
          <a className="cursor-pointer select-none text-2xl font-bold" href={'/'}>
            <span className="bg-gradient-to-r from-[#007BD3] to-[#034373] bg-clip-text text-transparent">Smart</span>
            <span className="ml-1 bg-gradient-to-r from-[#14CD68] to-[#0B6E38] bg-clip-text text-transparent">
              Safe
            </span>
          </a>
        </div>
        <div className="flex items-center gap-2">
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="inline-flex w-full justify-center rounded-md px-4 py-3 text-sm font-medium hover:bg-slate-400 hover:bg-opacity-20">
              {getChainInfo(chainId) ? (
                <img className="h-6 w-6" src={getChainInfo(chainId).icon} alt={getChainInfo(chainId).name} />
              ) : (
                '--'
              )}
              <ChevronDownIcon
                className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                aria-hidden="true"
              />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute left-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-slate-600 py-3 shadow-lg focus:outline-none">
                {chains.map(item => (
                  <Menu.Item key={item.chainId}>
                    <div
                      className="flex cursor-pointer items-center px-5 py-2 hover:bg-slate-500"
                      onClick={() => {
                        handleSwitch(item.chainId);
                      }}
                    >
                      {item.name}
                      {item.chainId === chainId ? (
                        <span className="ml-2 inline-block h-2 w-2 rounded-full bg-gray-100 opacity-70 hover:bg-gray-200" />
                      ) : null}
                    </div>
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
          {isConnected ? (
            <Button
              onClick={() => {
                setAccountVisible(true);
              }}
            >
              {/* {ensName ?? ellipsizeText({ text: address })} */}
              {ellipsizeText({ text: account })}
            </Button>
          ) : (
            <Button
              onClick={() => {
                setConnectVisible(true);
              }}
            >
              Connect
            </Button>
          )}
        </div>
      </div>
      <ConnectModal
        visible={connectVisible}
        onClose={() => {
          setConnectVisible(false);
        }}
      />
      <AccountModal
        visible={accountVisible}
        onClose={() => {
          setAccountVisible(false);
        }}
      />
    </header>
  );
}

export default Header;
