import { useCallback, useEffect, useState } from 'react';
import { WalletContext } from '@/context/wallet';
import VaultFactoryABI from '@/abi/VaultFactory.json';
import useContract from '@/hooks/useContract';
import { getContracts } from '@/config';
import { createContainer } from '../tools';
import toast from '@/utils/toast';

function useVaults() {
  const { account, chainId } = WalletContext.useContainer();

  const { getContract } = useContract();

  const [vaults, setVaults] = useState([]);

  const getVaults = useCallback(async () => {
    try {
      if (account == null) {
        setVaults([]);
        return;
      }
      const vaultFactory = getContracts(chainId).vaultFactory;
      const contract = getContract(vaultFactory, VaultFactoryABI);
      if (contract == null) {
        setVaults([]);
        return;
      }
      const _vaults = await contract.getAccountVaults(account);
      setVaults(_vaults ?? []);
    } catch (err) {
      console.log(err);
      setVaults([]);
    }
  }, [chainId, getContract, account]);

  const createVault = useCallback(async () => {
    try {
      const vaultFactory = getContracts(chainId).vaultFactory;
      const contract = getContract(vaultFactory, VaultFactoryABI);
      const tx = await contract.createVault();
      await tx.wait();
      getVaults();
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [chainId, getContract, getVaults]);

  useEffect(() => {
    getVaults();
  }, [getVaults]);

  return { vaults, getVaults, createVault };
}

export const VaultsContext = createContainer(useVaults);
