import { useCallback } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Button from '../Button';
import Modal from '../Modal';

import { ReactComponent as Link } from '@/assets/link.svg';
import { ReactComponent as Copy } from '@/assets/copy.svg';
import { WalletContext } from '@/context/wallet';
import { getBlockExplorerUrl } from '@/config';

function AccountModal(props) {
  const { visible, onClose } = props;

  const { account, disconnect, chainId } = WalletContext.useContainer();
  const blockExplorerUrl = getBlockExplorerUrl(chainId);

  const handDisconnect = useCallback(() => {
    disconnect();
    onClose?.();
  }, [disconnect, onClose]);

  const handleCopy = useCallback(() => {
    // message.success(localizedMessage({ id: 'account.copy.successful' }))
  }, []);

  return (
    <Modal visible={visible} onClose={onClose} className="p-4 lg:p-6">
      <div className="text-2xl font-bold">Account</div>
      <div className="mt-4 flex items-center gap-2">
        <a
          className="flex items-center gap-2 hover:opacity-70"
          href={`${blockExplorerUrl}/address/${account}`}
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          <span className="text-xs lg:text-sm">{account}</span>
          <i className="w-4 opacity-30">
            <Link />
          </i>
        </a>
        <CopyToClipboard text={account} onCopy={handleCopy}>
          <i className="hidden w-3 cursor-pointer opacity-30 hover:opacity-20 lg:inline-block">
            <Copy />
          </i>
        </CopyToClipboard>
      </div>
      <CopyToClipboard text={account} onCopy={handleCopy}>
        <Button className="mt-4 w-full lg:hidden">Copy address</Button>
      </CopyToClipboard>
      <Button className="mt-4 w-full" onClick={handDisconnect}>
        Disconnect
      </Button>
    </Modal>
  );
}

export default AccountModal;
