import { useCallback, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import useContract from './useContract';
import ERC20ABI from '@/abi/ERC20.json';
import VaultABI from '@/abi/Vault.json';
import { WalletContext } from '@/context/wallet';
import toast from '@/utils/toast';

function useVault(props) {
  // const {} = props
  const { account, provider } = WalletContext.useContainer();
  const { getContract } = useContract();

  const [vault, setVault] = useState();
  const [depositToken, setDepositToken] = useState();
  const [withdrawToken, setWithdrawToken] = useState();
  const [depositDecimals, setDepositDecimals] = useState();
  const [withdrawDecimals, setwithdrawDecimals] = useState();
  const [walletBalance, setWalletBalance] = useState();
  const [vaultBalance, setVaultBalance] = useState();
  const [amount, setAmount] = useState('');

  const getWalletBalance = useCallback(async () => {
    if (depositToken == null) {
      setWalletBalance(null);
      return;
    }
    if (account == null) {
      setWalletBalance(null);
      return;
    }
    if (provider == null) {
      setWalletBalance(null);
      return;
    }
    if (depositToken === '') {
      const balance = await provider.getBalance(account);
      setWalletBalance(ethers.utils.formatEther(balance));
      setDepositDecimals(18);
      return;
    }
    const contract = getContract(depositToken, ERC20ABI);
    if (contract == null) {
      setWalletBalance(null);
      return;
    }
    const [balance, _decimals] = await Promise.all([contract.balanceOf(account), contract.decimals()]);

    setWalletBalance(ethers.utils.formatUnits(balance, _decimals));
    setDepositDecimals(_decimals);
  }, [getContract, depositToken, account, provider]);

  const getVaultBalance = useCallback(async () => {
    if (withdrawToken == null) {
      setVaultBalance(null);
      return;
    }
    if (vault == null) {
      setVaultBalance(null);
      return;
    }
    if (provider == null) {
      setWalletBalance(null);
      return;
    }
    if (withdrawToken === '') {
      const balance = await provider.getBalance(vault);
      setVaultBalance(ethers.utils.formatEther(balance));
      setwithdrawDecimals(18);
      return;
    }
    const contract = getContract(withdrawToken, ERC20ABI);
    if (contract == null) {
      setVaultBalance(null);
      return;
    }

    const [balance, _decimals] = await Promise.all([contract.balanceOf(vault), contract.decimals()]);

    setVaultBalance(ethers.utils.formatUnits(balance, _decimals));
    setwithdrawDecimals(_decimals);
  }, [getContract, withdrawToken, vault, provider]);

  const deposit = useCallback(async () => {
    // TODO: deposit
    try {
      const _amount = ethers.utils.parseUnits(amount, depositDecimals);
      const _walletBalance = ethers.utils.parseUnits(walletBalance, depositDecimals);
      if (_amount.gt(_walletBalance)) {
        throw new Error('Input exceeds wallet balance');
      }
      let tx;
      if (depositToken === '') {
        const signer = provider.getSigner();
        tx = await signer.sendTransaction({
          to: vault,
          value: _amount,
        });
      } else {
        const contract = getContract(depositToken, ERC20ABI);
        tx = await contract.transfer(vault, _amount);
      }
      await tx.wait();
      toast.success('Success');
      getWalletBalance();
      getVaultBalance();
      setAmount('');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [
    getContract,
    vault,
    provider,
    depositToken,
    amount,
    walletBalance,
    depositDecimals,
    getWalletBalance,
    getVaultBalance,
  ]);

  const withdraw = useCallback(async () => {
    try {
      let _withdrawToken = withdrawToken;
      if (_withdrawToken === '') {
        _withdrawToken = ethers.constants.AddressZero;
      }
      const contract = getContract(vault, VaultABI);
      const tx = await contract.sweepToOwner(_withdrawToken);
      await tx.wait();
      toast.success('Success');
      getWalletBalance();
      getVaultBalance();
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, withdrawToken, getWalletBalance, getVaultBalance]);

  useEffect(() => {
    getWalletBalance();
  }, [getWalletBalance]);

  useEffect(() => {
    getVaultBalance();
  }, [getVaultBalance]);

  return {
    vault,
    setVault,
    setDepositToken,
    amount,
    setAmount,
    setWithdrawToken,
    depositDecimals,
    withdrawDecimals,
    walletBalance,
    vaultBalance,
    deposit,
    withdraw,
  };
}

export default useVault;
