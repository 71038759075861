import { useCallback, useState } from 'react';

const useLoading = fn => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [result, setResult] = useState();

  const _fn = useCallback(
    async params => {
      setLoading(true);
      setError();
      setResult();
      try {
        const _result = params != null ? await fn(params) : await fn();
        setResult(_result);
      } catch (err) {
        console.log(err);
        setError(err);
      }
      setLoading(false);
      return undefined;
    },
    [fn]
  );

  return { loading, error, result, fn: _fn };
};

export default useLoading;
