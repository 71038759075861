import { useCallback, useEffect, useRef, useState } from 'react';
import { ethers } from 'ethers';

import useContract from './useContract';
import ERC20ABI from '@/abi/ERC20.json';
import VaultABI from '@/abi/Vault.json';
import ParaSpaceSupplyMonitorABI from '@/abi/monitor/ParaSpaceSupplyMonitor.json';
import ParaSpaceYieldABI from '@/abi/ParaSpaceYield.json';
// import ParaSpaceGatewayABI from '@/abi/paraspace/ParaSpaceGateway.json';
import { WalletContext } from '@/context/wallet';
import useGasLimit from './useGasLimit';
import toast from '@/utils/toast';
import useGelato from './useGelato';
import { hasString } from '@/utils/helper';

function useParaSpaceYield(props) {
  const { logic, monitor } = props ?? {};

  const { provider } = WalletContext.useContainer();

  const { getContract } = useContract();
  const { calcGasLimit } = useGasLimit();
  const { createTask, getDedicatedMsgSender } = useGelato();

  const [vault, setVault] = useState();
  const [token, setToken] = useState();
  const [amount, setAmount] = useState('');

  const [exitToken, setExitToken] = useState();
  const [exitAmount, setExitAmount] = useState('');
  const exitMax = useRef(false);

  const [sweepToken, setSweepToken] = useState();

  const [taskToken, setTaskToken] = useState();
  const [stakePoolLiquidity, setStakePoolLiquidity] = useState();
  const [liquidityExit, setLiquidityExit] = useState('');
  const [liquidityEnter, setLiquidityEnter] = useState('');
  const [vaultEnter, setVaultEnter] = useState('');
  const [intervalTime, setIntervalTime] = useState('');

  const [robot, setRobot] = useState('0x5Ca91327dF003aFA2207b374D5f19730CCa67e2C');

  const [vaultBalance, setVaultBalance] = useState();
  const [position, setPosition] = useState();
  const [agreements, setAgreements] = useState();
  const [executor, setExecutor] = useState();

  const getBalance = useCallback(async () => {
    setVaultBalance(null);
    if (provider == null || vault == null) {
      return;
    }
    try {
      if (hasString(token)) {
        const contract = getContract(token, ERC20ABI);
        const balance = await contract.balanceOf(vault);
        const decimals = await contract.decimals();
        setVaultBalance(ethers.utils.formatUnits(balance, decimals));
      } else {
        const balance = await provider.getBalance(vault);
        setVaultBalance(ethers.utils.formatEther(balance));
      }
    } catch (err) {
      console.log(err);
    }
  }, [provider, vault, token, getContract]);

  const getPosition = useCallback(async () => {
    setPosition(null);
    if (vault == null) {
      return;
    }
    try {
      const contract = getContract(logic, ParaSpaceYieldABI);
      const result = await contract.getUserERC20SupplyData(vault);
      setPosition(result?.filter(item => item.xTokenBalance.gt(0)));
    } catch (err) {
      console.log(err);
    }
  }, [getContract, logic, vault]);

  const getAgreement = useCallback(async () => {
    setAgreements(null);
    if (vault == null) {
      return;
    }
    try {
      const contract = getContract(logic, ParaSpaceYieldABI);
      const result = await contract.getUserAgreements(vault);
      setAgreements(result?.filter(item => item.agreementId.gt(0)));
    } catch (err) {
      console.log(err);
    }
  }, [getContract, logic, vault]);

  const enter = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      let decimals = 18;
      if (hasString(token)) {
        const ERC20 = getContract(token, ERC20ABI);
        decimals = await ERC20.decimals();
      }
      const _amount = ethers.utils.parseUnits(amount, decimals);
      const _vaultBalance = ethers.utils.parseUnits(vaultBalance, decimals);
      if (_amount.gt(_vaultBalance)) {
        throw new Error('amount exceeds vault balance');
      }
      const iface = new ethers.utils.Interface(ParaSpaceYieldABI);
      let args;
      if (hasString(token)) {
        args = iface.encodeFunctionData('enter', [token, _amount]);
      } else {
        args = iface.encodeFunctionData('enterETH', [_amount]);
      }
      const gasLimit = await contract.estimateGas.execute(logic, args);
      const tx = await contract.execute(logic, args, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      getBalance();
      getPosition();
      setAmount('');
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, logic, amount, vaultBalance, token, calcGasLimit, getBalance, getPosition]);

  const getStakePoolBalance = useCallback(async () => {
    setStakePoolLiquidity(null);
    const contract = getContract(logic, ParaSpaceYieldABI);
    if (contract == null) {
      return;
    }
    try {
      let asset = taskToken;
      if (!hasString(taskToken)) {
        asset = await contract.getWETHAddress();
      }
      const pTokenAddress = await contract.getPTokenAddress(asset);
      const ERC20 = getContract(asset, ERC20ABI);
      const decimals = await ERC20.decimals();
      const result = await ERC20.balanceOf(pTokenAddress);
      setStakePoolLiquidity(ethers.utils.formatUnits(result, decimals));
    } catch (err) {
      console.log(err);
    }
  }, [getContract, taskToken, logic]);

  const exit = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      // position
      const iface = new ethers.utils.Interface(ParaSpaceYieldABI);
      let args;
      let asset;
      let _amount;
      const logicContract = getContract(logic, ParaSpaceYieldABI);
      if (hasString(exitToken)) {
        const ERC20 = getContract(exitToken, ERC20ABI);
        const decimals = await ERC20.decimals();
        asset = exitToken;
        _amount = ethers.utils.parseUnits(exitAmount, decimals);
      } else {
        asset = await logicContract.getWETHAddress();
        _amount = ethers.utils.parseEther(exitAmount);
      }
      const balance =
        position?.filter(item => item.underlyingAsset === asset)?.[0]?.xTokenBalance ?? ethers.constants.Zero;
      if (_amount.gt(balance)) {
        throw new Error('amount exceeds supply balance');
      }

      if (hasString(exitToken)) {
        args = iface.encodeFunctionData('exit', [exitToken, exitMax.current ? ethers.constants.MaxUint256 : _amount]);
      } else {
        args = iface.encodeFunctionData('exitETH', [exitMax.current ? ethers.constants.MaxUint256 : _amount]);
      }
      const gasLimit = await contract.estimateGas.execute(logic, args);
      const tx = await contract.execute(logic, args, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      getPosition();
      getAgreement();
      setExitAmount('');
      exitMax.current = false;
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, logic, calcGasLimit, getPosition, getAgreement, exitToken, exitAmount, position]);

  const claim = useCallback(
    async agreementId => {
      const contract = getContract(vault, VaultABI);
      try {
        const iface = new ethers.utils.Interface(ParaSpaceYieldABI);
        const args = iface.encodeFunctionData('claimAgreement', [agreementId]);
        const gasLimit = await contract.estimateGas.execute(logic, args);
        const tx = await contract.execute(logic, args, { gasLimit: calcGasLimit(gasLimit) });
        await tx.wait();
        getBalance();
        getAgreement();
        toast.success('Success');
        return tx;
      } catch (err) {
        console.log(err);
        toast.error(err?.reason ?? err?.message ?? 'Error');
      }
    },
    [getContract, vault, logic, calcGasLimit, getBalance, getAgreement]
  );

  const sweep = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const gasLimit = await contract.estimateGas.sweepToOwner(sweepToken);
      const tx = await contract.sweepToOwner(sweepToken, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      getPosition();
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, sweepToken, calcGasLimit, getPosition]);

  const getExecutor = useCallback(async () => {
    try {
      const sender = await getDedicatedMsgSender();
      setExecutor(sender);
    } catch (err) {
      setExecutor();
    }
  }, [getDedicatedMsgSender]);

  const createExitTask = useCallback(async () => {
    try {
      const vaultFace = new ethers.utils.Interface(VaultABI);
      const selector = vaultFace.getSighash(vaultFace.getFunction('execute'));
      // resolver
      const resolverFace = new ethers.utils.Interface(ParaSpaceSupplyMonitorABI);
      let asset;
      let _decimals;
      if (!hasString(taskToken)) {
        const contract = getContract(logic, ParaSpaceYieldABI);
        asset = await contract.getWETHAddress();
        _decimals = 18;
      } else {
        const contract = getContract(taskToken, ERC20ABI);
        asset = taskToken;
        _decimals = await contract.decimals();
      }
      const _liquidityExit = ethers.utils.parseUnits(liquidityExit, _decimals);
      const _liquidityEnter = ethers.utils.parseUnits(liquidityEnter, _decimals);
      const _vaultEnter = ethers.utils.parseUnits(vaultEnter, _decimals);

      const resolverData = resolverFace.encodeFunctionData('checker(address,address,uint256,uint256,uint256,uint256)', [
        asset,
        vault,
        _liquidityExit,
        _vaultEnter,
        _liquidityEnter,
        intervalTime,
      ]);
      const { tx } = await createTask({
        name: 'ParaSpace Yield Exit',
        execAddress: vault,
        execSelector: selector,
        startTime: 0,
        useTreasury: true,
        dedicatedMsgSender: true,
        resolverAddress: monitor[0],
        resolverData: resolverData,
        // resolverAbi: JSON.stringify(counterAbi),
      });
      setLiquidityExit('');
      setLiquidityEnter('');
      setVaultEnter('');
      setIntervalTime('');
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [
    createTask,
    taskToken,
    vault,
    monitor,
    liquidityExit,
    liquidityEnter,
    vaultEnter,
    intervalTime,
    logic,
    getContract,
  ]);

  const approveAutomate = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const authorized = await contract.hasImplementation(executor, logic);
      if (authorized) {
        throw new Error('authorized');
      }
      const gasLimit = await contract.estimateGas.grantImplementation(executor, logic);
      const tx = await contract.grantImplementation(executor, logic, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [calcGasLimit, executor, getContract, logic, vault]);

  const approve = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const authorized = await contract.hasImplementation(robot, logic);
      if (authorized) {
        throw new Error('authorized');
      }
      const gasLimit = await contract.estimateGas.grantImplementation(robot, logic);
      const tx = await contract.grantImplementation(robot, logic, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      setRobot('');
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, robot, logic, calcGasLimit]);

  useEffect(() => {
    getBalance();
  }, [getBalance]);

  useEffect(() => {
    getPosition();
  }, [getPosition]);

  useEffect(() => {
    getAgreement();
  }, [getAgreement]);

  useEffect(() => {
    getStakePoolBalance();
  }, [getStakePoolBalance]);

  useEffect(() => {
    getExecutor();
  }, [getExecutor]);

  const handleExitAsset = useCallback(data => {
    setExitToken(data?.address);
    setExitAmount('');
    exitMax.current = false;
  }, []);

  const handleExitMax = useCallback(async () => {
    const logicContract = getContract(logic, ParaSpaceYieldABI);
    let asset;
    let decimals = 18;
    if (hasString(exitToken)) {
      const ERC20 = getContract(exitToken, ERC20ABI);
      decimals = await ERC20.decimals();
      asset = exitToken;
    } else {
      asset = await logicContract.getWETHAddress();
    }
    const balance = position?.filter(item => item.underlyingAsset === asset)?.[0]?.xTokenBalance;
    if (balance) {
      setExitAmount(ethers.utils.formatUnits(balance, decimals));
    } else {
      setExitAmount('');
    }
    exitMax.current = true;
  }, [logic, exitToken, position, getContract]);

  const handleExitAmount = useCallback(
    event => {
      setExitAmount(event.target.value);
      exitMax.current = false;
    },
    [setExitAmount]
  );

  const handleSweepAsset = useCallback(data => {
    setSweepToken(data?.address);
    exitMax.current = false;
  }, []);

  return {
    vault,
    setVault,
    token,
    setToken,
    amount,
    setAmount,
    exitToken,
    setExitToken,
    exitAmount,
    setExitAmount,
    handleExitAmount,
    handleExitAsset,
    handleExitMax,
    handleSweepAsset,
    sweep,
    taskToken,
    setTaskToken,
    stakePoolLiquidity,
    liquidityExit,
    setLiquidityExit,
    liquidityEnter,
    setLiquidityEnter,
    vaultEnter,
    setVaultEnter,
    intervalTime,
    setIntervalTime,
    robot,
    setRobot,
    vaultBalance,
    position,
    agreements,
    enter,
    exit,
    claim,
    createExitTask,
    approveAutomate,
    executor,
    approve,
  };
}

export default useParaSpaceYield;
