const Contracts = {
  1: {
    vaultFactory: '0x38E043138F3Ab137807Ee5226F3447E83200f455',
    extensions: [
      {
        router: 'spark_boost',
        name: 'Spark boost',
        address: '0xE523E3b90b6A2a5Fd956e39A5A4Ba5e5B061A6A1',
        describe: 'Spark boost is xxx, is xxx, is xxx, is xxx.',
        monitor: ['0xBAF7F5202224e58D092396307AA3fAB88FAf2609'],
        supportTokens: ['ETH'],
      },
      {
        router: 'spark_supply',
        name: 'Spark supply',
        address: '0x052a6469aD8C8C40D5218357fA9ED2C68Bd09fa8',
        describe: 'Spark supply is xxx, is xxx, is xxx, is xxx.',
        monitor: ['0x3d57604dEcd8a3A2b1681f8f92eFeB2c05c38BF1'],
        supportTokens: ['ETH'],
      },
      {
        router: 'lido_makerdao_savings',
        name: 'Lido MakerDao USDC-DAI-sDAI savings',
        address: '0x6Dae9515DEb20F9875B4A383D353a97E0A6815E4',
        describe: 'Lido MakerDao USDC-DAI-sDAI savings is xxx, is xxx, is xxx, is xxx.',
        monitor: ['0x48DdC74324eE45B6d119c6491Df689429Eb0634C'],
        supportTokens: ['USDC'],
      },
      {
        router: 'lido_spark_savings_v2',
        name: 'Lido Spark wstETH-DAI-sDAI savings V2',
        address: '0x2B30A948Fe2bf1FF67506aaBB3dF3fE0603d128F',
        describe: 'Lido Spark wstETH-DAI-sDAI savings is xxx, is xxx, is xxx, is xxx.',
        monitor: ['0x9986d4A5b334Cc6a5C01c3515398Cb603b7365C6', '0x82E6de20a8bF006D354af659a26634D2d399E46C'],
        supportTokens: ['ETH', 'wstETH'],
      },
      // {
      //   router: 'lido_spark_savings',
      //   name: 'Lido Spark wstETH-DAI-sDAI savings',
      //   address: '0x71122Cd26c5f1E18826652708C3e00D1cf837DA4',
      //   describe: 'Lido Spark wstETH-DAI-sDAI savings is xxx, is xxx, is xxx, is xxx.',
      //   monitor: ['0x9986d4A5b334Cc6a5C01c3515398Cb603b7365C6'],
      //   supportTokens: ['ETH', 'wstETH'],
      // },
      {
        router: 'compound_comet_yield_usdc',
        name: 'Compound V3 USDC yield',
        address: '0xf680783e00567C3b87A21cAF5316d1F1F99bba43',
        describe: 'Compound V3 USDC yield is xxx, is xxx, is xxx, is xxx.',
        monitor: ['0xF47C48a73D8Ce2fC3bE83f502EAF82A0D54495e6'],
        supportTokens: ['USDC'],
      },
      {
        router: 'lido_aave_leverage',
        name: 'Lido Aave wstETH-ETH leverage',
        address: '0xEaCE2ac2eBB0fA433217f077156b3928a52a9a7a',
        describe: 'Lido Aave wstETH-ETH leverage is xxx, is xxx, is xxx, is xxx.',
        monitor: ['0x12B494fEa89Ec96A5CDf68ac2aeE7471e58806C5'],
        supportTokens: ['ETH'],
      },
      {
        router: 'compound_comet_yield',
        name: 'Compound V3 ETH yield',
        address: '0x8C93468cCF7072b01BD71D7372312F955CDa5B5F',
        describe: 'Compound V3 ETH yield is xxx, is xxx, is xxx, is xxx.',
        monitor: ['0x38e41C44fee6449708B80f0bfcCC454D4B9C4E85'],
        supportTokens: ['ETH'],
      },
      {
        router: 'para_space_yield',
        name: 'ParaSpace yield',
        address: '0x6c94C936f6107e282023a0b4785895836c74A39e',
        describe: 'ParaSpace yield is xxx, is xxx, is xxx, is xxx.',
        monitor: ['0x5aF8Fe772Fc35bA0f47d1E06D6CcDBd8076AC32a'],
        supportTokens: ['ETH', 'USDC', 'USDT'],
      },
    ],
    tokens: [
      {
        symbol: 'ETH',
        address: '',
      },
      {
        symbol: 'WETH',
        address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      },
      {
        symbol: 'wstETH',
        address: '0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0',
      },
      {
        symbol: 'USDC',
        address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      },
      {
        symbol: 'USDT',
        address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      },
      {
        symbol: 'COMP',
        address: '0xc00e94Cb662C3520282E6f5717214004A7f26888',
      },
      {
        symbol: 'DAI',
        address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
      },
    ],
    taskTreasury: '0x2807B4aE232b624023f87d0e237A3B1bf200Fd99',
  },
  10: {
    vaultFactory: '0x38E043138F3Ab137807Ee5226F3447E83200f455',
    extensions: [
      {
        router: 'ironbank_supply',
        name: 'IronBank Supply',
        address: '0x7dBC2e7a5e3aC6dd6773472642e3EFAEab0De3e9',
        describe: 'IronBank Supply is xxx, is xxx, is xxx, is xxx.',
        rewards: ['0x00a35FD824c717879BF370E70AC6868b95870Dfb'],
        swap: '0x0FD9B163568a141C3fAD0E5469deAe899d08B068',
        monitor: ['0xf47F67366dbB658D63E9A3DBAB39A5cb07Fa2A9F'],
        supportTokens: ['WETH', 'USDC', 'USDT', 'DAI', 'WBTC', 'OP'],
      },
    ],
    taskTreasury: '0xB3f5503f93d5Ef84b06993a1975B9D21B962892F',
    tokens: [
      {
        symbol: 'WETH',
        address: '0x4200000000000000000000000000000000000006',
      },
      {
        symbol: 'USDC',
        address: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
      },
      {
        symbol: 'USDT',
        address: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
      },
      {
        symbol: 'DAI',
        address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
      },
      {
        symbol: 'WBTC',
        address: '0x68f180fcCe6836688e9084f035309E29Bf0A2095',
      },
      {
        symbol: 'OP',
        address: '0x4200000000000000000000000000000000000042',
      },
    ],
  },
};

export default Contracts;
