import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';
import { ReactComponent as Link } from '@/assets/link.svg';
import Back from '@/components/Back';
import Button from '@/components/Button';
import Input from '@/components/Input';
import Vault from '@/components/Vault';
import { getBlockExplorerUrl, getExtension } from '@/config';
import { WalletContext } from '@/context/wallet';
import { ellipsizeText } from '@/utils/input';
import useSparkBoost from '@/hooks/useSparkBoost';
import SelectToken from '@/components/SelectToken';
import useLoading from '@/hooks/useLoading';
import useAutomate from '@/hooks/useAutomate';
import { hasString } from '@/utils/helper';
import { formatPercentages, formatNumber } from '@/utils/format';
import { bigDivide } from '@/utils/math';
import useSupportTokens from '@/hooks/useSupportTokens';

function App() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { router } = state ?? {};
  const { chainId } = WalletContext.useContainer();
  const { name, address, describe, monitor, supportTokens } = getExtension(chainId, router) ?? {};
  const blockExplorerUrl = getBlockExplorerUrl(chainId);
  const {
    vault,
    setVault,
    setEnterToken,
    amount,
    setAmount,
    enterFactor,
    setEnterFactor,
    vaultBalance,
    helper,
    position,
    daiAmount,
    setDaiAmount,
    daiBalance,
    poolBalance,
    swapPoolBalance,
    robot,
    setRobot,
    enter,
    transfer,
    exit,
    deleverageFactor,
    setDeleverageFactor,
    deleverage,
    exitBySwap,
    exitFactor,
    setExitFactor,
    triggerFactor,
    setTriggerFactor,
    targetFactor,
    setTargetFactor,
    interval,
    setInterval,
    loanliquidity,
    setLoanLiquidity,
    swapLiquidity,
    setSwapLiquidity,
    createExitTask,
    approveAutomate,
    executor,
    approve,
    approveFlashLoan,
  } = useSparkBoost({ logic: address, monitor });

  const { fundsAddress, fundsBalance, depositAmount, setDepositAmount, deposit } = useAutomate();

  const handleVaultChange = useCallback(
    address => {
      setVault(address);
    },
    [setVault]
  );

  const chainIdRef = useRef();
  useEffect(() => {
    if (chainIdRef.current == null) {
      chainIdRef.current = chainId;
    } else {
      if (chainIdRef.current !== chainId) {
        navigate('/', { replace: true });
      }
    }
  }, [chainId, navigate]);

  useEffect(() => {
    if (chainIdRef.current != null && address == null) {
      navigate('/', { replace: true });
    }
  }, [address, navigate]);

  const { tokens } = useSupportTokens({ supportTokens });

  const { loading: enterLoading, fn: enterFn } = useLoading(enter);
  const { loading: transferLoading, fn: transferFn } = useLoading(transfer);
  const { loading: exitLoading, fn: exitFn } = useLoading(exit);
  const { loading: deleverageLoading, fn: deleverageFn } = useLoading(deleverage);
  const { loading: exitBySwapLoading, fn: exitBySwapFn } = useLoading(exitBySwap);
  const { loading: depositAutomateFundsLoading, fn: depositAutomateFundsFn } = useLoading(deposit);
  const { loading: createExitTaskLoading, fn: createExitTaskFn } = useLoading(createExitTask);
  const { loading: approveAutomateLoading, fn: approveAutomateFn } = useLoading(approveAutomate);
  const { loading: approveLoading, fn: approveFn } = useLoading(approve);
  const { loading: approveFlashLoanLoading, fn: approveFlashLoanFn } = useLoading(approveFlashLoan);

  const currentHealthFactor = position
    ? ethers.constants.MaxUint256.eq(position.healthFactor)
      ? 'MaxUint256'
      : formatNumber(ethers.utils.formatEther(position.healthFactor))
    : undefined;

  return (
    <div>
      <Back />
      <div className="mt-5">
        <h1 className="text-2xl font-bold">{name}</h1>
        <div className="mt-2 flex items-center gap-2">
          <span>Contract:</span>
          <a
            href={`${blockExplorerUrl}/address/${address}`}
            className="flex items-center gap-2 hover:opacity-70"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
              {ellipsizeText({ text: address })}
            </span>
            <i className="w-4 opacity-30">
              <Link />
            </i>
          </a>
        </div>
        <p className="mt-2 opacity-30">{describe}</p>
      </div>
      <Vault onChange={handleVaultChange} />
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Enter</h3>
        <SelectToken
          tokens={tokens}
          onChange={token => {
            setEnterToken(token?.address);
          }}
        />
        <p className="mt-2 text-sm text-white/70">{`Vault balance: ${vaultBalance ?? '--'}`}</p>
        <div className="mt-2">
          <Input
            placeholder="amount"
            onMax={() => {
              setAmount(vaultBalance ?? '');
            }}
            onChange={event => {
              setAmount(event.target.value);
            }}
            value={amount}
          />
          <Input
            className="mt-4"
            placeholder="health factor"
            onChange={event => {
              setEnterFactor(event.target.value);
            }}
            value={enterFactor}
          />
          <p className="mt-2 text-sm text-white/70">{`Borrow power used (settlement when reaching 100%): ${formatPercentages(
            bigDivide(1, enterFactor)
          )} `}</p>
          <Button
            className="mt-4 min-w-[96px]"
            disabled={vault == null || !hasString(amount) || !hasString(enterFactor)}
            loading={enterLoading}
            onClick={enterFn}
          >
            Enter
          </Button>
        </div>
      </div>
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Approve to FlashloanHelper</h3>
        {helper ? (
          <div className="mt-2 flex items-center gap-2">
            <span>Contract:</span>
            <a
              href={`${blockExplorerUrl}/address/${helper}`}
              className="flex items-center gap-2 hover:opacity-70"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
                {ellipsizeText({ text: helper })}
              </span>
              <i className="w-4 opacity-30">
                <Link />
              </i>
            </a>
          </div>
        ) : (
          '--'
        )}
        <h4 className="mt-4 text-xl">You must approve this before exit</h4>
        <div className="mt-4 flex items-center gap-4">
          <Button
            className="min-w-[96px]"
            disabled={vault == null || helper == null}
            loading={approveFlashLoanLoading}
            onClick={approveFlashLoanFn}
          >
            Approve
          </Button>
        </div>
      </div>
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Exit</h3>
        <h4 className="mt-5 text-xl">Position detail</h4>
        {position ? (
          <div className="mt-5 w-full text-xs text-white/70 lg:text-sm">
            <p className="mt-2">{`totalCollateralBase: ${formatNumber(
              ethers.utils.formatUnits(position.totalCollateralBase, 8)
            )}`}</p>
            <p className="mt-2">{`totalDebtBase: ${formatNumber(
              ethers.utils.formatUnits(position.totalDebtBase, 8)
            )}`}</p>
            <p className="mt-2">{`availableBorrowsBase: ${formatNumber(
              ethers.utils.formatUnits(position.availableBorrowsBase, 8)
            )}`}</p>
            <p className="mt-2">{`currentLiquidationThreshold: ${formatPercentages(
              ethers.utils.formatUnits(position.currentLiquidationThreshold, 4)
            )}`}</p>
            <p className="mt-2">{`ltv: ${formatPercentages(ethers.utils.formatUnits(position.ltv, 4))}`}</p>
            <p className="mt-2">{`healthFactor: ${currentHealthFactor}`}</p>
            <p className="mt-2">{`ETH supply balance: ${formatNumber(
              ethers.utils.formatUnits(position.supplyAmount, position.supplyTokenDecimals)
            )}`}</p>
            <p className="mt-2">{`DAI borrow balance: ${formatNumber(
              ethers.utils.formatUnits(position.borrowAmount, position.borrowTokenDecimals)
            )}`}</p>
          </div>
        ) : null}
        <div className="mt-5">
          <p>Make sure the DAI balance in the vault is sufficient to repay the loan</p>
          <p className="mt-2 text-sm text-white/70">{`Vault DAI balance: ${daiBalance ?? '--'}`}</p>
          <div className="mt-2 flex items-center justify-between gap-4">
            <Input
              onChange={event => {
                setDaiAmount(event.target.value);
              }}
              value={daiAmount}
              placeholder="DAI amount"
            />
            <Button
              className="min-w-[96px]"
              disabled={vault == null || !hasString(daiAmount)}
              loading={transferLoading}
              onClick={transferFn}
            >
              Transfer
            </Button>
          </div>
        </div>
        <div className="mt-6 flex items-center gap-4">
          <Button
            className="min-w-[96px]"
            disabled={vault == null || position == null}
            loading={exitLoading}
            onClick={exitFn}
          >
            Exit
          </Button>
        </div>
        <div className="mt-5">
          <h3 className="text-2xl font-bold">Deleverage with Uniswap flash swap (optional)</h3>
          <div className="mt-6">
            <Input
              placeholder="target health factor, must bigger than current"
              onChange={event => {
                setDeleverageFactor(event.target.value);
              }}
              value={deleverageFactor}
            />
            <p className="mt-2 text-sm text-white/70">{`Borrow power used (settlement when reaching 100%): ${formatPercentages(
              bigDivide(1, deleverageFactor)
            )} `}</p>
            <Button
              className="mt-2 min-w-[96px]"
              disabled={vault == null || position == null || !hasString(deleverageFactor)}
              loading={deleverageLoading}
              onClick={deleverageFn}
            >
              Deleverage
            </Button>
          </div>
        </div>
        <div className="mt-5">
          <h3 className="text-2xl font-bold">Exit with Uniswap flash swap (optional)</h3>
          <div className="mt-6 flex items-center gap-4">
            <Button
              className="min-w-[96px]"
              disabled={vault == null || position == null}
              loading={exitBySwapLoading}
              onClick={exitBySwapFn}
            >
              Exit with flash swap
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Automate (Beta)</h3>
        <h4 className="mt-5 text-xl">Automate gas funds</h4>
        <div>
          <a
            className="mt-2 inline-flex items-center gap-2 text-xs hover:opacity-70"
            href={`${blockExplorerUrl}/address/${fundsAddress}`}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
              {ellipsizeText({ text: fundsAddress })}
            </span>
            <i className="w-4 opacity-30">
              <Link />
            </i>
          </a>
        </div>
        <p className="mt-2 text-sm text-white/70">{`Funds balance: ${fundsBalance ?? '--'}`}</p>
        <div className="mt-2 flex items-center justify-between gap-4">
          <Input
            onChange={event => {
              setDepositAmount(event.target.value);
            }}
            value={depositAmount}
            placeholder="gas amount"
          />
          <Button
            className="min-w-[96px]"
            disabled={vault == null || !hasString(depositAmount)}
            loading={depositAutomateFundsLoading}
            onClick={depositAutomateFundsFn}
          >
            Deposit
          </Button>
        </div>
        <p className="mt-5 text-base lg:text-lg">Create a task to auto exit or deleverage</p>
        <p className="mt-2 text-xs lg:text-sm">{`Deleverage trigger health factor, used to deleverage from`}</p>
        <Input
          className="mt-2"
          placeholder="deleverage trigger health factor"
          onChange={event => {
            setTriggerFactor(event.target.value);
          }}
          value={triggerFactor}
        />
        <p className="mt-2 text-sm text-white/70">{`Borrow power used (settlement when reaching 100%): ${formatPercentages(
          bigDivide(1, triggerFactor)
        )} `}</p>
        <p className="mt-2 text-xs lg:text-sm">{`Deleverage target health factor, used to deleverage to`}</p>
        <Input
          className="mt-2"
          placeholder="deleverage target health factor"
          onChange={event => {
            setTargetFactor(event.target.value);
          }}
          value={targetFactor}
        />
        <p className="mt-2 text-sm text-white/70">{`Borrow power used (settlement when reaching 100%): ${formatPercentages(
          bigDivide(1, targetFactor)
        )} `}</p>
        <p className="mt-2 text-xs lg:text-sm">{`Exit health factor, used to exit all with Uniswap flash swap`}</p>
        <Input
          className="mt-2"
          placeholder="exit health factor"
          onChange={event => {
            setExitFactor(event.target.value);
          }}
          value={exitFactor}
        />
        <p className="mt-2 text-sm text-white/70">{`Borrow power used (settlement when reaching 100%): ${formatPercentages(
          bigDivide(1, exitFactor)
        )} `}</p>
        <p className="mt-2 text-xs lg:text-sm">
          {poolBalance
            ? `Spark min ETH liquidity to exit, current ETH liquidity is ${poolBalance}`
            : 'Spark min ETH liquidity to exit'}
        </p>
        <Input
          className="mt-2"
          placeholder="min liquidity to exit"
          onChange={event => {
            setLoanLiquidity(event.target.value);
          }}
          value={loanliquidity}
        />
        <p className="mt-2 text-xs lg:text-sm">
          {swapPoolBalance
            ? `Uniswap USDC-ETH pool min ETH liquidity to exit, current ETH liquidity is ${swapPoolBalance}`
            : 'Uniswap USDC-ETH pool min ETH liquidity to exit'}
        </p>
        <Input
          className="mt-2"
          placeholder="min liquidity to exit"
          onChange={event => {
            setSwapLiquidity(event.target.value);
          }}
          value={swapLiquidity}
        />
        <p className="mt-2 text-xs lg:text-sm">{`Min seconds between exiting and entering, used to prevent frequent entering and exiting`}</p>
        <Input
          className="mt-2"
          placeholder="min interval seconds"
          onChange={event => {
            setInterval(event.target.value);
          }}
          value={interval}
        />
        <Button
          className="mt-2 min-w-[96px]"
          disabled={
            vault == null ||
            !hasString(exitFactor) ||
            !hasString(triggerFactor) ||
            !hasString(targetFactor) ||
            !hasString(interval) ||
            !hasString(loanliquidity) ||
            !hasString(swapLiquidity)
          }
          loading={createExitTaskLoading}
          onClick={createExitTaskFn}
        >
          Create
        </Button>
        <p className="mt-2 text-base lg:text-lg">{`Approve a executor to execute the tasks`}</p>
        <div>
          <a
            className="mt-2 inline-flex items-center gap-2 text-xs hover:opacity-70"
            href={`${blockExplorerUrl}/address/${executor}`}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
              {ellipsizeText({ text: executor })}
            </span>
            <i className="w-4 opacity-30">
              <Link />
            </i>
          </a>
        </div>
        <Button
          className="mt-2 min-w-[96px]"
          disabled={vault == null || executor == null}
          loading={approveAutomateLoading}
          onClick={approveAutomateFn}
        >
          Approve
        </Button>
      </div>
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Robot</h3>
        <p className="mt-6 text-sm text-white/70">Approve a robot to auto exit</p>
        <div className="mt-4 flex items-center justify-between gap-4">
          <Input
            onChange={event => {
              setRobot(event.target.value);
            }}
            value={robot}
          />
          <Button className="min-w-[96px]" disabled={vault == null} loading={approveLoading} onClick={approveFn}>
            Approve
          </Button>
        </div>
      </div>
    </div>
  );
}

export default App;
