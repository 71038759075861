// import { useCallback } from 'react';
// import classNames from 'classnames';
import Back from '@/components/Back';
import { ellipsizeText } from '@/utils/input';
import { WalletContext } from '@/context/wallet';
import { getBlockExplorerUrl } from '@/config';
import { ReactComponent as Link } from '@/assets/link.svg';
import Input from '@/components/Input';
import Button from '@/components/Button';
import useAutomate from '@/hooks/useAutomate';
import useLoading from '@/hooks/useLoading';

const Funds = props => {
  const { chainId } = WalletContext.useContainer();
  const blockExplorerUrl = getBlockExplorerUrl(chainId);
  const {
    walletBalance,
    fundsAddress: address,
    fundsBalance,
    depositAmount,
    setDepositAmount,
    deposit,
    withdrawAmount,
    setWithdrawAmount,
    withdraw,
  } = useAutomate();

  const { loading: depositLoading, fn: depositFn } = useLoading(deposit);
  const { loading: withdrawLoading, fn: withdrawFn } = useLoading(withdraw);

  return (
    <div className="mt-6 rounded-xl bg-black/90 p-4 md:mt-10 lg:p-6">
      <div className="text-base font-bold lg:text-xl">My automate funds</div>
      <div className="mt-2 flex items-center gap-2 text-xs lg:text-base">
        <span>Contract:</span>
        <a
          href={`${blockExplorerUrl}/address/${address}`}
          className="flex items-center gap-2 hover:opacity-70"
          rel="nofollow noopener noreferrer"
          target="_blank"
          onClick={e => {
            let event = e;
            if (!event) {
              event = window.event;
            }
            event.nativeEvent.cancelBubble = true;
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
          }}
        >
          <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
            {ellipsizeText({ text: address })}
          </span>
          <i className="w-4 opacity-70">
            <Link />
          </i>
        </a>
      </div>
      <p className="mt-4 text-sm text-white/70">{`Wallet balance: ${walletBalance ?? '--'}`}</p>
      <div className="mt-2 flex items-center justify-between gap-4">
        <Input
          onChange={event => {
            setDepositAmount(event.target.value);
          }}
          value={depositAmount}
          placeholder="gas amount"
        />
        <Button
          className="min-w-[96px]"
          disabled={depositAmount == null || depositAmount.length === 0}
          loading={depositLoading}
          onClick={depositFn}
        >
          Deposit
        </Button>
      </div>
      <p className="mt-4 text-sm text-white/70">{`Funds balance: ${fundsBalance ?? '--'}`}</p>
      <div className="mt-2 flex items-center justify-between gap-4">
        <Input
          onMax={() => {
            setWithdrawAmount(fundsBalance ?? '');
          }}
          onChange={event => {
            setWithdrawAmount(event.target.value);
          }}
          value={withdrawAmount}
          placeholder="gas amount"
        />
        <Button
          className="min-w-[96px]"
          disabled={withdrawAmount == null || withdrawAmount === 0}
          loading={withdrawLoading}
          onClick={withdrawFn}
        >
          Withdraw
        </Button>
      </div>
    </div>
  );
};

const Tasks = props => {
  const { chainId } = WalletContext.useContainer();
  const { tasks } = useAutomate();

  return (
    <div className="mt-6 rounded-xl bg-black/90 p-4 md:mt-10 lg:p-6">
      <div className="text-base font-bold lg:text-xl">My automate tasks</div>
      {tasks == null || tasks.length === 0 ? (
        <div className="mt-6 border-t border-white border-opacity-10 p-6 text-center text-sm lg:text-base">No data</div>
      ) : (
        <div className="mt-6 px-2 lg:px-6">
          {tasks.map((item, index) => {
            return (
              <div
                className="-mx-2 flex cursor-pointer items-center justify-between border-t border-white border-opacity-10 px-3 py-6 hover:bg-gray-400 hover:bg-opacity-10 md:flex-row lg:-mx-6"
                key={index}
                onClick={() => {
                  window.open(`https://app.gelato.network/task/${item.taskId}?chainId=${chainId}`);
                  // const newWindow = window.open(url, '_blank', 'nofollow noopener noreferrer');
                  // if (newWindow) newWindow.opener = null;
                }}
              >
                <div className="flex w-full flex-col gap-2">
                  <div className="gap-3 truncate text-xs font-bold lg:text-lg">
                    <span>{item.name}</span>
                  </div>
                  <div className="flex flex-col gap-1 text-xs md:flex-row md:items-center">
                    taskId:
                    <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
                      {ellipsizeText({ text: item.taskId })}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

function App() {
  return (
    <div>
      <Back />
      <Funds />
      <Tasks />
    </div>
  );
}

export default App;
