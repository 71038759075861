import { useCallback, useEffect, useState } from 'react';
import { ethers } from 'ethers';

import useContract from './useContract';
import ERC20ABI from '@/abi/ERC20.json';
import VaultABI from '@/abi/Vault.json';
import SparkBoostMonitorABI from '@/abi/monitor/SparkBoostMonitor.json';
import SparkBoostABI from '@/abi/SparkBoost.json';
import { WalletContext } from '@/context/wallet';
import useGasLimit from './useGasLimit';
import toast from '@/utils/toast';
import useGelato from './useGelato';

function useSparkBoost(props) {
  const { logic, monitor } = props ?? {};

  const { provider, account } = WalletContext.useContainer();

  const { getContract } = useContract();
  const { calcGasLimit } = useGasLimit();
  const { createTask, getDedicatedMsgSender } = useGelato();

  const [vault, setVault] = useState();
  const [enterToken, setEnterToken] = useState();
  const [enterFactor, setEnterFactor] = useState();
  const [amount, setAmount] = useState('');

  const [vaultBalance, setVaultBalance] = useState();
  const [position, setPosition] = useState();

  const [daiAmount, setDaiAmount] = useState('');
  const [daiBalance, setDaiBalance] = useState();

  const [deleverageFactor, setDeleverageFactor] = useState('');

  const [poolBalance, setPoolBalance] = useState();
  const [swapPoolBalance, setSwapPoolBalance] = useState();

  const [exitFactor, setExitFactor] = useState('');
  const [triggerFactor, setTriggerFactor] = useState('');
  const [targetFactor, setTargetFactor] = useState('');
  const [interval, setInterval] = useState('');
  const [loanliquidity, setLoanLiquidity] = useState('');
  const [swapLiquidity, setSwapLiquidity] = useState('');

  const [robot, setRobot] = useState('0x5Ca91327dF003aFA2207b374D5f19730CCa67e2C');

  const [executor, setExecutor] = useState();

  const [helper, setHelper] = useState();

  const getBalance = useCallback(async () => {
    setVaultBalance(null);
    if (provider == null || account == null || vault == null) {
      return;
    }
    try {
      if (enterToken) {
        const ERC20 = getContract(enterToken, ERC20ABI);
        const balance = await ERC20.balanceOf(vault);
        const decimals = await ERC20.decimals();
        setVaultBalance(ethers.utils.formatUnits(balance, decimals));
      } else {
        const balance = await provider.getBalance(vault);
        setVaultBalance(ethers.utils.formatEther(balance));
      }
    } catch (err) {
      console.log(err);
    }
  }, [provider, account, vault, enterToken, getContract]);

  const getHelper = useCallback(async () => {
    try {
      const contract = getContract(logic, SparkBoostABI);
      if (contract == null) {
        return;
      }
      const result = await contract.flashHelper();
      setHelper(result);
    } catch (err) {
      console.log(err);
    }
  }, [getContract, logic]);

  const getPosition = useCallback(async () => {
    setPosition(null);
    if (vault == null) {
      return;
    }
    try {
      const contract = getContract(logic, SparkBoostABI);
      const result = await contract.getPosition(vault);
      setPosition(result);
    } catch (err) {
      console.log(err);
    }
  }, [getContract, logic, vault]);

  const getDAIBalance = useCallback(async () => {
    const contract = getContract(logic, SparkBoostABI);
    if (contract == null || vault == null) {
      return;
    }
    try {
      const result = await contract.getVaultBorrowAssetBalance(vault);
      setDaiBalance(ethers.utils.formatUnits(result[0], result[1]));
    } catch (err) {
      console.log(err);
    }
  }, [getContract, vault, logic]);

  const enter = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      let decimals = 18;
      if (enterToken) {
        const ERC20 = getContract(enterToken, ERC20ABI);
        decimals = await ERC20.decimals();
      }
      const _amount = ethers.utils.parseUnits(amount, decimals);
      const _vaultBalance = ethers.utils.parseUnits(vaultBalance, decimals);
      if (_amount.gt(_vaultBalance)) {
        throw new Error('amount exceeds vault balance');
      }
      const _healthFactor = ethers.utils.parseEther(enterFactor);
      const iface = new ethers.utils.Interface(SparkBoostABI);
      let args;
      if (enterToken) {
        args = iface.encodeFunctionData('enter', [enterToken, _amount, _healthFactor]);
      } else {
        args = iface.encodeFunctionData('enterETH', [_amount, _healthFactor]);
      }
      const gasLimit = await contract.estimateGas.execute(logic, args);
      const tx = await contract.execute(logic, args, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      getBalance();
      getPosition();
      setAmount('');
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, logic, amount, vaultBalance, enterToken, enterFactor, calcGasLimit, getBalance, getPosition]);

  const transfer = useCallback(async () => {
    try {
      const contract = getContract(logic, SparkBoostABI);
      const dai = await contract.borrowAsset();
      const ERC20 = getContract(dai, ERC20ABI);
      const decimals = await ERC20.decimals();
      const _amount = ethers.utils.parseUnits(daiAmount, decimals);
      const tx = await ERC20.transfer(vault, _amount);
      await tx.wait();
      getDAIBalance();
      setDaiAmount('');
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, logic, vault, daiAmount, getDAIBalance]);

  const exit = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const iface = new ethers.utils.Interface(SparkBoostABI);
      let args;
      args = iface.encodeFunctionData('exitByBalance');
      const gasLimit = await contract.estimateGas.execute(logic, args);
      const tx = await contract.execute(logic, args, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      getBalance();
      getPosition();
      getDAIBalance();
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, logic, calcGasLimit, getBalance, getPosition, getDAIBalance]);

  const deleverage = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const iface = new ethers.utils.Interface(SparkBoostABI);
      const _healthFactor = ethers.utils.parseEther(deleverageFactor);
      let args;
      args = iface.encodeFunctionData('deleverage', [_healthFactor]);
      const gasLimit = await contract.estimateGas.execute(logic, args);
      const tx = await contract.execute(logic, args, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      getPosition();
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, logic, deleverageFactor, calcGasLimit, getPosition]);

  const exitBySwap = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const iface = new ethers.utils.Interface(SparkBoostABI);
      let args;
      args = iface.encodeFunctionData('exitBySwap');
      const gasLimit = await contract.estimateGas.execute(logic, args);
      const tx = await contract.execute(logic, args, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      getBalance();
      getPosition();
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, logic, calcGasLimit, getBalance, getPosition]);

  const getPoolBalance = useCallback(async () => {
    const contract = getContract(logic, SparkBoostABI);
    if (contract == null) {
      return;
    }
    try {
      const result = await contract.getPoolETHBalance();
      setPoolBalance(ethers.utils.formatUnits(result[0], result[1]));
    } catch (err) {
      console.log(err);
    }
  }, [getContract, logic]);

  const getSwapPoolBalance = useCallback(async () => {
    const contract = getContract(logic, SparkBoostABI);
    if (contract == null) {
      return;
    }
    try {
      const result = await contract.getSwapPoolETHBalance();
      setSwapPoolBalance(ethers.utils.formatUnits(result[0], result[1]));
    } catch (err) {
      console.log(err);
    }
  }, [getContract, logic]);

  const getExecutor = useCallback(async () => {
    try {
      const sender = await getDedicatedMsgSender();
      setExecutor(sender);
    } catch (err) {
      setExecutor();
    }
  }, [getDedicatedMsgSender]);

  const createExitTask = useCallback(async () => {
    try {
      const vaultFace = new ethers.utils.Interface(VaultABI);
      const selector = vaultFace.getSighash(vaultFace.getFunction('execute'));
      // resolver

      const resolverFace = new ethers.utils.Interface(SparkBoostMonitorABI);
      const _triggerFactor = ethers.utils.parseEther(triggerFactor);
      const _targetFactor = ethers.utils.parseEther(targetFactor);
      const _exitFactor = ethers.utils.parseEther(exitFactor);
      const _loanliquidity = ethers.utils.parseEther(loanliquidity);
      const _swapLiquidity = ethers.utils.parseEther(swapLiquidity);

      const resolverData = resolverFace.encodeFunctionData(
        'checker(address,uint256,uint256,uint256,uint256,uint256,uint256)',
        [vault, _triggerFactor, _targetFactor, _exitFactor, _loanliquidity, _swapLiquidity, interval]
      );
      const { tx } = await createTask({
        name: 'Spark Boost Exit',
        execAddress: vault,
        execSelector: selector,
        startTime: 0,
        useTreasury: true,
        dedicatedMsgSender: true,
        resolverAddress: monitor[0],
        resolverData: resolverData,
        // resolverAbi: JSON.stringify(counterAbi),
      });
      setLoanLiquidity('');
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [createTask, vault, monitor, triggerFactor, targetFactor, exitFactor, loanliquidity, swapLiquidity, interval]);

  const approveAutomate = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const authorized = await contract.hasImplementation(executor, logic);
      if (authorized) {
        throw new Error('authorized');
      }
      const gasLimit = await contract.estimateGas.grantImplementation(executor, logic);
      const tx = await contract.grantImplementation(executor, logic, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [calcGasLimit, executor, getContract, logic, vault]);

  const approve = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const authorized = await contract.hasImplementation(robot, logic);
      if (authorized) {
        throw new Error('authorized');
      }
      const gasLimit = await contract.estimateGas.grantImplementation(robot, logic);
      const tx = await contract.grantImplementation(robot, logic, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      setRobot('');
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, robot, logic, calcGasLimit]);

  const approveFlashLoan = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      if (helper == null) {
        throw new Error('helper is null');
      }
      const authorized = await contract.hasImplementation(helper, logic);
      if (authorized) {
        throw new Error('authorized');
      }
      const gasLimit = await contract.estimateGas.grantImplementation(helper, logic);
      const tx = await contract.grantImplementation(helper, logic, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, helper, logic, calcGasLimit]);

  useEffect(() => {
    getBalance();
  }, [getBalance]);

  useEffect(() => {
    getHelper();
  }, [getHelper]);

  useEffect(() => {
    getPosition();
  }, [getPosition]);

  useEffect(() => {
    getDAIBalance();
  }, [getDAIBalance]);

  useEffect(() => {
    getPoolBalance();
  }, [getPoolBalance]);

  useEffect(() => {
    getSwapPoolBalance();
  }, [getSwapPoolBalance]);

  useEffect(() => {
    getExecutor();
  }, [getExecutor]);

  return {
    vault,
    setVault,
    enterToken,
    setEnterToken,
    enterFactor,
    setEnterFactor,
    amount,
    setAmount,
    vaultBalance,
    helper,
    position,
    daiAmount,
    setDaiAmount,
    daiBalance,
    poolBalance,
    swapPoolBalance,
    exitFactor,
    setExitFactor,
    triggerFactor,
    setTriggerFactor,
    targetFactor,
    setTargetFactor,
    interval,
    setInterval,
    loanliquidity,
    setLoanLiquidity,
    swapLiquidity,
    setSwapLiquidity,
    robot,
    setRobot,
    enter,
    transfer,
    exit,
    deleverageFactor,
    setDeleverageFactor,
    deleverage,
    exitBySwap,
    createExitTask,
    approveAutomate,
    executor,
    approve,
    approveFlashLoan,
  };
}

export default useSparkBoost;
