import { useCallback } from 'react';
import { WalletContext } from '@/context/wallet';
import { ETH, getContracts } from '@/config';
import useContract from './useContract';
import TaskTreasuryABI from '@/abi/TaskTreasury.json';

function useTaskTreasury() {
  const { chainId, account } = WalletContext.useContainer();

  const { getContract } = useContract();

  const getTaskTreasury = useCallback(() => {
    return getContracts(chainId)?.taskTreasury;
  }, [chainId]);

  const getTaskTreasuryContract = useCallback(() => {
    return getContract(getTaskTreasury(), TaskTreasuryABI);
  }, [getContract, getTaskTreasury]);

  const userTokenBalance = useCallback(
    async (user = account) => {
      const contract = getTaskTreasuryContract();

      if (contract == null) {
        return;
      }
      if (account == null) {
        return;
      }

      const balance = await contract.userTokenBalance(user, ETH);

      return balance;
    },
    [getTaskTreasuryContract, account]
  );

  const depositFunds = useCallback(
    async amount => {
      const contract = getTaskTreasuryContract();

      if (contract == null) {
        return;
      }

      const tx = await contract.depositFunds(account, ETH, amount, { value: amount });
      await tx.wait();
      return tx;
    },
    [getTaskTreasuryContract, account]
  );

  const withdrawFunds = useCallback(
    async amount => {
      const contract = getTaskTreasuryContract();

      if (contract == null) {
        return;
      }

      const tx = await contract.withdrawFunds(account, ETH, amount);
      await tx.wait();
      return tx;
    },
    [getTaskTreasuryContract, account]
  );

  return { getTaskTreasury, userTokenBalance, depositFunds, withdrawFunds };
}

export default useTaskTreasury;
