import { useCallback, useEffect, useRef, useState } from 'react';
import { ethers } from 'ethers';
import { WalletContext } from '@/context/wallet';
import useGelato from './useGelato';
import useTaskTreasury from './useTaskTreasury';
import toast from '@/utils/toast';

function useAutomate() {
  const { provider } = WalletContext.useContainer();

  const [walletBalance, setWalletBalance] = useState();
  const [fundsAddress, setFundsAddress] = useState();
  const [fundsBalance, setFundsBalance] = useState();
  const [depositAmount, setDepositAmount] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');

  const fundsBalanceRef = useRef();

  const { getTaskTreasury, userTokenBalance, depositFunds, withdrawFunds } = useTaskTreasury();

  const getWalletBalance = useCallback(async () => {
    try {
      if (provider == null) {
        return;
      }
      const signer = provider.getSigner();
      const balance = await signer.getBalance('latest');
      if (balance == null) {
        setWalletBalance();
      } else {
        setWalletBalance(ethers.utils.formatEther(balance));
      }
    } catch (err) {
      console.log(err);
    }
  }, [provider]);

  const getFundsBalance = useCallback(async () => {
    try {
      const balance = await userTokenBalance();
      if (balance == null) {
        setFundsBalance();
        fundsBalanceRef.current = null;
      } else {
        const amount = ethers.utils.formatEther(balance);
        setFundsBalance(amount);
        fundsBalanceRef.current = amount;
      }
    } catch (err) {
      console.log(err);
    }
  }, [userTokenBalance]);

  const deposit = useCallback(async () => {
    try {
      const tx = await depositFunds(ethers.utils.parseEther(depositAmount));
      await tx.wait();
      await getWalletBalance();
      await getFundsBalance();
      setDepositAmount('');
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [depositFunds, depositAmount, getWalletBalance, getFundsBalance]);

  const withdraw = useCallback(async () => {
    try {
      const amount = ethers.utils.parseEther(withdrawAmount);
      const balance = ethers.utils.parseEther(fundsBalanceRef.current ?? '0');
      if (amount.gt(balance)) {
        toast.error('Input is bigger than balance');
        return;
      }
      const tx = await withdrawFunds(amount);
      await tx.wait();
      await getWalletBalance();
      await getFundsBalance();
      setWithdrawAmount('');
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [withdrawFunds, withdrawAmount, getWalletBalance, getFundsBalance]);

  const { activeTasks } = useGelato();

  const [tasks, setTasks] = useState([]);

  const getTasks = useCallback(async () => {
    try {
      const list = await activeTasks();
      // console.log(list);
      setTasks(list ?? []);
    } catch (err) {
      console.log(err);
      setTasks([]);
    }
  }, [activeTasks]);

  useEffect(() => {
    getWalletBalance();
  }, [getWalletBalance]);

  useEffect(() => {
    setFundsAddress(getTaskTreasury());
  }, [getTaskTreasury]);

  useEffect(() => {
    getFundsBalance();
  }, [getFundsBalance]);

  useEffect(() => {
    getTasks();
  }, [getTasks]);

  return {
    walletBalance,
    fundsAddress,
    fundsBalance,
    depositAmount,
    setDepositAmount,
    deposit,
    withdrawAmount,
    setWithdrawAmount,
    withdraw,
    tasks,
  };
}

export default useAutomate;
