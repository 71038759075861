import { ReactComponent as Link } from '@/assets/link.svg';
import Back from '@/components/Back';
import Button from '@/components/Button';
import Input from '@/components/Input';
import Vault from '@/components/Vault';

function App() {
  return (
    <div>
      <Back />
      <div className="mt-5">
        <h1 className="text-2xl font-bold">Aave Revolving</h1>
        <div className="mt-2 flex items-center gap-2">
          <span>Contract:</span>
          <a
            href="https://goerli.etherscan.io/address/0x54baa998771639628ffc0206c3b916c466b79c89"
            className="flex items-center gap-2 hover:opacity-70"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
              0x54baa9...66b79c89
            </span>
            <i className="w-4 opacity-30">
              <Link />
            </i>
          </a>
        </div>
        <p className="mt-2 opacity-30">Aave revolving is xxx, revolving is xxx, revolving is xxx, revolving is xxx</p>
      </div>
      <Vault />
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Enter</h3>
        <h4 className="mt-5 text-xl">USDC</h4>
        <p className="text-sm text-white/70">Vault balance: 1.142586</p>
        <div className="mt-6 flex items-center justify-between gap-4">
          <Input onMax={() => {}} />
          <Button>Enter</Button>
        </div>
      </div>
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Bot</h3>
        <p className="mt-5 text-sm text-white/70">Approve a robot to automake claim and rebalance</p>
        <div className="mt-6 flex items-center justify-between gap-4">
          <Input />
          <Button>Approve</Button>
        </div>
      </div>
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Exit</h3>
        <p className="mt-5 text-sm text-white/70">Vault detail: 1.142586</p>
        <div className="mt-6 flex items-center justify-between gap-4">
          <h4 className="text-xl">USDC</h4>
          <Button>Exit</Button>
        </div>
      </div>
    </div>
  );
}

export default App;
