import { useCallback, useEffect, useState } from 'react';
import { ethers } from 'ethers';

import useContract from './useContract';
import ERC20ABI from '@/abi/ERC20.json';
import VaultABI from '@/abi/Vault.json';
import SparkStakeMonitorABI from '@/abi/monitor/SparkStakeMonitor.json';
import SparkStakeRebalanceMonitorABI from '@/abi/monitor/SparkStakeRebalanceMonitor.json';
import LidoSparkSavingsABI from '@/abi/LidoSparkSavings.json';
import { WalletContext } from '@/context/wallet';
import useGasLimit from './useGasLimit';
import toast from '@/utils/toast';
import useGelato from './useGelato';

function useLidoSparkSavings(props) {
  const { logic, monitor } = props ?? {};

  const { provider, account } = WalletContext.useContainer();

  const { getContract } = useContract();
  const { calcGasLimit } = useGasLimit();
  const { createTask, getDedicatedMsgSender } = useGelato();

  const [vault, setVault] = useState();
  const [token, setToken] = useState();
  const [amount, setAmount] = useState('');
  const [healthFactor, setHealthFactor] = useState('');

  const [vaultBalance, setVaultBalance] = useState();
  const [position, setPosition] = useState();
  const [poolWstETHBalance, setPoolWstETHBalance] = useState();

  const [executor, setExecutor] = useState();

  const [rebalanceHealthFactor, setRebalanceHealthFactor] = useState('');

  const [riskFactor, setRiskFactor] = useState('');
  const [daiPriceThreshold, setDaiPriceThreshold] = useState('');
  const [stETHPriceThreshold, setStEThPriceThreshold] = useState('');
  const [liquidityWstETH, setLiquidityWstETH] = useState('');
  const [daiBorrowRateThreshold, setDaiBorrowRateThreshold] = useState('');

  const [targetFactor, setTargetFactor] = useState('');
  const [minFactor, setMinFactor] = useState('');
  const [maxFactor, setMaxFactor] = useState('');

  const [robot, setRobot] = useState('0x5Ca91327dF003aFA2207b374D5f19730CCa67e2C');

  const getBalance = useCallback(async () => {
    setVaultBalance(null);
    if (provider == null || account == null || vault == null) {
      return;
    }
    try {
      if (token) {
        const ERC20 = getContract(token, ERC20ABI);
        const balance = await ERC20.balanceOf(vault);
        const decimals = await ERC20.decimals();
        setVaultBalance(ethers.utils.formatUnits(balance, decimals));
      } else {
        const balance = await provider.getBalance(vault);
        setVaultBalance(ethers.utils.formatEther(balance));
      }
    } catch (err) {
      console.log(err);
    }
  }, [provider, account, vault, token, getContract]);

  const getPosition = useCallback(async () => {
    setPosition(null);
    if (vault == null) {
      return;
    }
    try {
      const logicContract = getContract(logic, LidoSparkSavingsABI);
      const result = await logicContract.getPosition(vault);
      setPosition(result);
    } catch (err) {
      console.log(err);
    }
  }, [getContract, logic, vault]);

  const getPoolWstETHBalance = useCallback(async () => {
    const monitorContract = getContract(monitor[0], SparkStakeMonitorABI);
    if (monitorContract == null) {
      return;
    }
    try {
      const result = await monitorContract.getSparkWstETHPoolLiquidity();
      setPoolWstETHBalance(ethers.utils.formatEther(result));
    } catch (err) {
      console.log(err);
    }
  }, [getContract, monitor]);

  const enter = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      let decimals = 18;
      let funName = 'enterETH';
      if (token) {
        const ERC20 = getContract(token, ERC20ABI);
        decimals = await ERC20.decimals();
        funName = 'enterWstETH';
      }
      const _amount = ethers.utils.parseUnits(amount, decimals);
      const _vaultBalance = ethers.utils.parseUnits(vaultBalance, decimals);
      if (_amount.gt(_vaultBalance)) {
        throw new Error('amount exceeds vault balance');
      }
      const _healthFactor = ethers.utils.parseEther(healthFactor);
      if (_healthFactor.lte(ethers.utils.parseEther('1'))) {
        throw new Error('health factor ≤ 1');
      }
      const iface = new ethers.utils.Interface(LidoSparkSavingsABI);
      const args = iface.encodeFunctionData(funName, [_amount, _healthFactor]);
      const gasLimit = await contract.estimateGas.execute(logic, args);
      const tx = await contract.execute(logic, args, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      getBalance();
      getPosition();
      setAmount('');
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, logic, token, amount, healthFactor, vaultBalance, calcGasLimit, getBalance, getPosition]);

  const exit = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const iface = new ethers.utils.Interface(LidoSparkSavingsABI);
      const args = iface.encodeFunctionData('exit');
      const gasLimit = await contract.estimateGas.execute(logic, args);
      const tx = await contract.execute(logic, args, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      getBalance();
      getPosition();
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, logic, calcGasLimit, getBalance, getPosition]);

  const rebalance = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const iface = new ethers.utils.Interface(LidoSparkSavingsABI);
      const args = iface.encodeFunctionData('rebalance', [ethers.utils.parseEther(rebalanceHealthFactor)]);
      const gasLimit = await contract.estimateGas.execute(logic, args);
      const tx = await contract.execute(logic, args, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      getBalance();
      getPosition();
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, logic, rebalanceHealthFactor, calcGasLimit, getBalance, getPosition]);

  const getExecutor = useCallback(async () => {
    try {
      const sender = await getDedicatedMsgSender();
      setExecutor(sender);
    } catch (err) {
      setExecutor();
    }
  }, [getDedicatedMsgSender]);

  const createExitTask = useCallback(async () => {
    try {
      const vaultFace = new ethers.utils.Interface(VaultABI);
      const selector = vaultFace.getSighash(vaultFace.getFunction('execute'));
      // resolver
      const resolverFace = new ethers.utils.Interface(SparkStakeMonitorABI);

      const _riskFactor = ethers.utils.parseEther(riskFactor);
      if (_riskFactor.lte(ethers.utils.parseEther('1'))) {
        throw new Error('risk health factor ≤ 1');
      }

      const resolverData = resolverFace.encodeFunctionData('checker(address,uint256,uint256,uint256,uint256,uint128)', [
        vault,
        _riskFactor,
        ethers.utils.parseUnits(daiPriceThreshold, 8),
        ethers.utils.parseUnits(stETHPriceThreshold, 18),
        ethers.utils.parseEther(liquidityWstETH),
        ethers.utils.parseUnits(daiBorrowRateThreshold, 27),
      ]);

      const { tx } = await createTask({
        name: 'Lido Spark wstETH-DAI-sDAI savings',
        execAddress: vault,
        execSelector: selector,
        startTime: 0,
        useTreasury: true,
        dedicatedMsgSender: true,
        resolverAddress: monitor[0],
        resolverData: resolverData,
        // resolverAbi: JSON.stringify(counterAbi),
      });
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [
    createTask,
    vault,
    riskFactor,
    monitor,
    daiPriceThreshold,
    stETHPriceThreshold,
    liquidityWstETH,
    daiBorrowRateThreshold,
  ]);

  const createRebalanceTask = useCallback(async () => {
    try {
      const vaultFace = new ethers.utils.Interface(VaultABI);
      const selector = vaultFace.getSighash(vaultFace.getFunction('execute'));
      // resolver
      const resolverFace = new ethers.utils.Interface(SparkStakeRebalanceMonitorABI);

      const _targetFactor = ethers.utils.parseEther(targetFactor);
      if (_targetFactor.lte(ethers.utils.parseEther('1'))) {
        throw new Error('target health factor ≤ 1');
      }
      const _minFactor = ethers.utils.parseEther(minFactor);
      if (_minFactor.lte(ethers.utils.parseEther('1'))) {
        throw new Error('min health factor ≤ 1');
      }
      if (_minFactor.gte(_targetFactor)) {
        throw new Error('min health factor greater than target health factor');
      }
      const _maxFactor = ethers.utils.parseEther(maxFactor);
      if (_maxFactor.lte(ethers.utils.parseEther('1'))) {
        throw new Error('max health factor ≤ 1');
      }
      if (_maxFactor.lte(_targetFactor)) {
        throw new Error('max health factor less than target health factor');
      }

      const resolverData = resolverFace.encodeFunctionData('checker(address,uint256,uint256,uint256)', [
        vault,
        _minFactor,
        _targetFactor,
        _maxFactor,
      ]);

      const { tx } = await createTask({
        name: 'Lido Spark wstETH-DAI-sDAI savings rebalance',
        execAddress: vault,
        execSelector: selector,
        startTime: 0,
        useTreasury: true,
        dedicatedMsgSender: true,
        resolverAddress: monitor[1],
        resolverData: resolverData,
        // resolverAbi: JSON.stringify(counterAbi),
      });
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [createTask, vault, monitor, targetFactor, minFactor, maxFactor]);

  const approveAutomate = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const authorized = await contract.hasImplementation(executor, logic);
      if (authorized) {
        throw new Error('authorized');
      }
      const gasLimit = await contract.estimateGas.grantImplementation(executor, logic);
      const tx = await contract.grantImplementation(executor, logic, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [calcGasLimit, executor, getContract, logic, vault]);

  const approve = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const authorized = await contract.hasImplementation(robot, logic);
      if (authorized) {
        throw new Error('authorized');
      }
      const gasLimit = await contract.estimateGas.grantImplementation(robot, logic);
      const tx = await contract.grantImplementation(robot, logic, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      setRobot('');
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, robot, logic, calcGasLimit]);

  useEffect(() => {
    getBalance();
  }, [getBalance]);

  useEffect(() => {
    getPosition();
  }, [getPosition]);

  useEffect(() => {
    getPoolWstETHBalance();
  }, [getPoolWstETHBalance]);

  useEffect(() => {
    getExecutor();
  }, [getExecutor]);

  return {
    vault,
    setVault,
    token,
    setToken,
    amount,
    setAmount,
    healthFactor,
    setHealthFactor,
    rebalanceHealthFactor,
    setRebalanceHealthFactor,
    riskFactor,
    setRiskFactor,
    daiPriceThreshold,
    setDaiPriceThreshold,
    stETHPriceThreshold,
    setStEThPriceThreshold,
    liquidityWstETH,
    setLiquidityWstETH,
    daiBorrowRateThreshold,
    setDaiBorrowRateThreshold,
    targetFactor,
    setTargetFactor,
    minFactor,
    setMinFactor,
    maxFactor,
    setMaxFactor,
    robot,
    setRobot,
    vaultBalance,
    position,
    poolWstETHBalance,
    enter,
    exit,
    rebalance,
    createExitTask,
    createRebalanceTask,
    approveAutomate,
    executor,
    approve,
  };
}

export default useLidoSparkSavings;
