import Ethereum from '@/assets/ethereum.png';
import Optimism from '@/assets/optimism.svg';

const Chains = {
  1: {
    name: 'Ethereum',
    icon: Ethereum,
    color: 'rgba(98, 126, 234, 1)',
  },
  5: {
    name: 'Goerli',
    icon: Ethereum,
    color: 'rgba(98, 126, 234, 1)',
  },
  10: {
    name: 'Optimism',
    icon: Optimism,
    color: 'rgba(255, 4, 32, 1)',
  },
};

export default Chains;
