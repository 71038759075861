import { useCallback, useEffect, useState } from 'react';
import { ethers } from 'ethers';

import useContract from './useContract';
import ERC20ABI from '@/abi/ERC20.json';
import VaultABI from '@/abi/Vault.json';
import MakerDaoStakeMonitorABI from '@/abi/monitor/MakerDaoStakeMonitor.json';
import LidoMakerDaoSavingsABI from '@/abi/LidoMakerDaoSavings.json';
import { WalletContext } from '@/context/wallet';
import useGasLimit from './useGasLimit';
import toast from '@/utils/toast';
import useGelato from './useGelato';

function useLidoMakerDaoSavings(props) {
  const { logic, monitor } = props ?? {};

  const { provider, account } = WalletContext.useContainer();

  const { getContract } = useContract();
  const { calcGasLimit } = useGasLimit();
  const { createTask, getDedicatedMsgSender } = useGelato();

  const [vault, setVault] = useState();
  const [token, setToken] = useState();
  const [amount, setAmount] = useState('');

  const [vaultBalance, setVaultBalance] = useState();
  const [position, setPosition] = useState();
  const [poolBalance, setPoolBalance] = useState();

  const [executor, setExecutor] = useState();

  const [liquidity, setLiquidity] = useState('');
  const [daiPriceThreshold, setDaiPriceThreshold] = useState('');
  const [usdcPriceThreshold, setUSDCPriceThreshold] = useState('');

  const [robot, setRobot] = useState('0x5Ca91327dF003aFA2207b374D5f19730CCa67e2C');

  const getBalance = useCallback(async () => {
    setVaultBalance(null);
    if (provider == null || account == null || vault == null) {
      return;
    }
    try {
      if (token) {
        const ERC20 = getContract(token, ERC20ABI);
        const balance = await ERC20.balanceOf(vault);
        const decimals = await ERC20.decimals();
        setVaultBalance(ethers.utils.formatUnits(balance, decimals));
      } else {
        const balance = await provider.getBalance(vault);
        setVaultBalance(ethers.utils.formatEther(balance));
      }
    } catch (err) {
      console.log(err);
    }
  }, [provider, account, vault, token, getContract]);

  const getPosition = useCallback(async () => {
    setPosition(null);
    if (vault == null) {
      return;
    }
    try {
      const logicContract = getContract(logic, LidoMakerDaoSavingsABI);
      const result = await logicContract.getPosition(vault);
      setPosition(result);
    } catch (err) {
      console.log(err);
    }
  }, [getContract, logic, vault]);

  const getPoolBalance = useCallback(async () => {
    const monitorContract = getContract(monitor[0], MakerDaoStakeMonitorABI);
    if (monitorContract == null) {
      return;
    }
    try {
      const result = await monitorContract.getPSMUSDCLiquidity();
      setPoolBalance(ethers.utils.formatUnits(result, 6));
    } catch (err) {
      console.log(err);
    }
  }, [getContract, monitor]);

  const enter = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      let decimals = 18;
      if (token) {
        const ERC20 = getContract(token, ERC20ABI);
        decimals = await ERC20.decimals();
      }
      const _amount = ethers.utils.parseUnits(amount, decimals);
      const _vaultBalance = ethers.utils.parseUnits(vaultBalance, decimals);
      if (_amount.gt(_vaultBalance)) {
        throw new Error('amount exceeds vault balance');
      }
      const iface = new ethers.utils.Interface(LidoMakerDaoSavingsABI);
      const args = iface.encodeFunctionData('enter', [_amount]);
      const gasLimit = await contract.estimateGas.execute(logic, args);
      const tx = await contract.execute(logic, args, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      getBalance();
      getPosition();
      setAmount('');
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, logic, token, amount, vaultBalance, calcGasLimit, getBalance, getPosition]);

  const exitAll = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const iface = new ethers.utils.Interface(LidoMakerDaoSavingsABI);
      const args = iface.encodeFunctionData('exitAll');
      const gasLimit = await contract.estimateGas.execute(logic, args);
      const tx = await contract.execute(logic, args, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      getBalance();
      getPosition();
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, logic, calcGasLimit, getBalance, getPosition]);

  const getExecutor = useCallback(async () => {
    try {
      const sender = await getDedicatedMsgSender();
      setExecutor(sender);
    } catch (err) {
      setExecutor();
    }
  }, [getDedicatedMsgSender]);

  const createExitTask = useCallback(async () => {
    try {
      const vaultFace = new ethers.utils.Interface(VaultABI);
      const selector = vaultFace.getSighash(vaultFace.getFunction('execute'));
      // resolver
      const resolverFace = new ethers.utils.Interface(MakerDaoStakeMonitorABI);

      const resolverData = resolverFace.encodeFunctionData('checker(address,uint256,uint256,uint256)', [
        vault,
        ethers.utils.parseUnits(daiPriceThreshold, 8),
        ethers.utils.parseUnits(usdcPriceThreshold, 8),
        ethers.utils.parseUnits(liquidity, 6),
      ]);

      const { tx } = await createTask({
        name: 'Lido MakerDao USDC-DAI-sDAI savings',
        execAddress: vault,
        execSelector: selector,
        startTime: 0,
        useTreasury: true,
        dedicatedMsgSender: true,
        resolverAddress: monitor[0],
        resolverData: resolverData,
        // resolverAbi: JSON.stringify(counterAbi),
      });
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [createTask, vault, monitor, liquidity, daiPriceThreshold, usdcPriceThreshold]);

  const approveAutomate = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const authorized = await contract.hasImplementation(executor, logic);
      if (authorized) {
        throw new Error('authorized');
      }
      const gasLimit = await contract.estimateGas.grantImplementation(executor, logic);
      const tx = await contract.grantImplementation(executor, logic, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [calcGasLimit, executor, getContract, logic, vault]);

  const approve = useCallback(async () => {
    const contract = getContract(vault, VaultABI);
    try {
      const authorized = await contract.hasImplementation(robot, logic);
      if (authorized) {
        throw new Error('authorized');
      }
      const gasLimit = await contract.estimateGas.grantImplementation(robot, logic);
      const tx = await contract.grantImplementation(robot, logic, { gasLimit: calcGasLimit(gasLimit) });
      await tx.wait();
      setRobot('');
      toast.success('Success');
      return tx;
    } catch (err) {
      console.log(err);
      toast.error(err?.reason ?? err?.message ?? 'Error');
    }
  }, [getContract, vault, robot, logic, calcGasLimit]);

  useEffect(() => {
    getBalance();
  }, [getBalance]);

  useEffect(() => {
    getPosition();
  }, [getPosition]);

  useEffect(() => {
    getPoolBalance();
  }, [getPoolBalance]);

  useEffect(() => {
    getExecutor();
  }, [getExecutor]);

  return {
    vault,
    setVault,
    token,
    setToken,
    amount,
    setAmount,
    liquidity,
    setLiquidity,
    daiPriceThreshold,
    setDaiPriceThreshold,
    usdcPriceThreshold,
    setUSDCPriceThreshold,
    robot,
    setRobot,
    vaultBalance,
    position,
    poolBalance,
    enter,
    exitAll,
    createExitTask,
    approveAutomate,
    executor,
    approve,
  };
}

export default useLidoMakerDaoSavings;
