import { useCallback, useEffect, useRef, useState, memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';
import { format, getUnixTime, secondsToMilliseconds } from 'date-fns';
import { ReactComponent as Link } from '@/assets/link.svg';
import Back from '@/components/Back';
import Button from '@/components/Button';
import Input from '@/components/Input';
import Vault from '@/components/Vault';
import { getBlockExplorerUrl, getExtension } from '@/config';
import { WalletContext } from '@/context/wallet';
import { ellipsizeText } from '@/utils/input';
import useParaSpaceYield from '@/hooks/useParaSpaceYield';
import SelectToken from '@/components/SelectToken';
import useLoading from '@/hooks/useLoading';
import useAutomate from '@/hooks/useAutomate';
import { hasString } from '@/utils/helper';
import useInterval from '@/hooks/useInterval';
import useSupportTokens from '@/hooks/useSupportTokens';

const Agreement = memo(props => {
  const { vault, agreement, claim } = props ?? {};
  const [release, setRelease] = useState(false);

  const releaseTime = agreement?.agreement?.releaseTime ?? 0;
  const claimable = agreement?.agreement?.tokenIdsOrAmounts?.[0];

  useInterval(() => {
    if (releaseTime > 0) {
      setRelease(getUnixTime(new Date()) >= releaseTime + 15);
    } else {
      setRelease(false);
    }
  }, 1000);

  const { loading: claimLoading, fn: claimFn } = useLoading(() => claim(agreement?.agreementId));

  return (
    <div>
      <p className="mt-2 text-xs text-white/70 lg:text-sm">{`claimable: ${claimable ?? '--'}`}</p>
      <p className="mt-2 text-xs text-white/70 lg:text-sm">{`releaseTime: ${
        releaseTime != null
          ? releaseTime === 0
            ? 0
            : format(secondsToMilliseconds(releaseTime), 'yyyy-MM-dd HH:mm:ss')
          : '--'
      }`}</p>
      <Button
        className="mt-4 min-w-[96px]"
        disabled={vault == null || agreement == null || !release}
        loading={claimLoading}
        onClick={claimFn}
      >
        Claim
      </Button>
    </div>
  );
});

function App() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { router } = state ?? {};
  const { chainId } = WalletContext.useContainer();
  const { name, address, describe, monitor, supportTokens } = getExtension(chainId, router) ?? {};
  const blockExplorerUrl = getBlockExplorerUrl(chainId);
  const {
    vault,
    setVault,
    setToken,
    amount,
    setAmount,
    exitAmount,
    handleExitAmount,
    handleExitAsset,
    handleExitMax,
    handleSweepAsset,
    sweep,
    setTaskToken,
    stakePoolLiquidity,
    liquidityExit,
    setLiquidityExit,
    liquidityEnter,
    setLiquidityEnter,
    vaultEnter,
    setVaultEnter,
    intervalTime,
    setIntervalTime,
    robot,
    setRobot,
    vaultBalance,
    position,
    agreements,
    enter,
    exit,
    claim,
    createExitTask,
    approveAutomate,
    executor,
    approve,
  } = useParaSpaceYield({ logic: address, monitor });

  const { fundsAddress, fundsBalance, depositAmount, setDepositAmount, deposit } = useAutomate();

  const handleVaultChange = useCallback(
    address => {
      setVault(address);
    },
    [setVault]
  );

  const chainIdRef = useRef();
  useEffect(() => {
    if (chainIdRef.current == null) {
      chainIdRef.current = chainId;
    } else {
      if (chainIdRef.current !== chainId) {
        navigate('/', { replace: true });
      }
    }
  }, [chainId, navigate]);

  useEffect(() => {
    if (chainIdRef.current != null && address == null) {
      navigate('/', { replace: true });
    }
  }, [address, navigate]);

  const { tokens } = useSupportTokens({ supportTokens });

  const { loading: enterLoading, fn: enterFn } = useLoading(enter);
  const { loading: exitLoading, fn: exitFn } = useLoading(exit);
  const { loading: sweepLoading, fn: sweepFn } = useLoading(sweep);
  const { loading: depositAutomateFundsLoading, fn: depositAutomateFundsFn } = useLoading(deposit);
  const { loading: createExitTaskLoading, fn: createExitTaskFn } = useLoading(createExitTask);
  const { loading: approveAutomateLoading, fn: approveAutomateFn } = useLoading(approveAutomate);
  const { loading: approveLoading, fn: approveFn } = useLoading(approve);

  return (
    <div>
      <Back />
      <div className="mt-5">
        <h1 className="text-2xl font-bold">{name}</h1>
        <div className="mt-2 flex items-center gap-2">
          <span>Contract:</span>
          <a
            href={`${blockExplorerUrl}/address/${address}`}
            className="flex items-center gap-2 hover:opacity-70"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
              {ellipsizeText({ text: address })}
            </span>
            <i className="w-4 opacity-30">
              <Link />
            </i>
          </a>
        </div>
        <p className="mt-2 opacity-30">{describe}</p>
      </div>
      <Vault onChange={handleVaultChange} />
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Enter</h3>
        <SelectToken
          tokens={tokens}
          onChange={token => {
            setToken(token?.address);
          }}
        />
        <p className="mt-2 text-sm text-white/70">{`Vault balance: ${vaultBalance ?? '--'}`}</p>
        <div className="mt-2">
          <Input
            placeholder="amount"
            onMax={() => {
              setAmount(vaultBalance ?? '');
            }}
            onChange={event => {
              setAmount(event.target.value);
            }}
            value={amount}
          />
          <Button
            className="mt-4 min-w-[96px]"
            disabled={vault == null || !hasString(amount)}
            loading={enterLoading}
            onClick={enterFn}
          >
            Enter
          </Button>
        </div>
      </div>
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Exit</h3>
        <p className="mt-2 text-white/70">Exiting ParaSpace requires two-step operation</p>
        <div>
          <a
            href={
              'https://docs.para.space/para-space/protocol-security-and-external-audits/withdrawal-and-borrow-timelock#timelock-parameters'
            }
            className="mt-2 inline-flex items-center gap-2 text-sm hover:opacity-70"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <span className="text-white/70">ParaSpace timelock parameters</span>
            <i className="w-4 opacity-30">
              <Link />
            </i>
          </a>
        </div>
        <h4 className="mt-5 text-xl">ParaSpace supply detail</h4>
        {position != null && position.length > 0 ? (
          position.map((item, index) => (
            <p className="mt-2 text-xs text-white/70 lg:text-sm" key={index}>{` ${ethers.utils.formatUnits(
              item.xTokenBalance,
              item.xTokenDecimals
            )} ${item.xTokenSymbol}`}</p>
          ))
        ) : (
          <p className="mt-2 text-xs text-white/70 lg:text-sm">no supply</p>
        )}
        <SelectToken tokens={tokens} onChange={handleExitAsset} />
        <Input
          className="mt-2"
          placeholder="amount"
          onMax={handleExitMax}
          onChange={handleExitAmount}
          value={exitAmount}
        />
        <Button
          className="mt-2 min-w-[96px]"
          disabled={vault == null || position == null || position.length === 0 || !hasString(exitAmount)}
          loading={exitLoading}
          onClick={exitFn}
        >
          Exit
        </Button>
        <h4 className="mt-5 text-xl">ParaSpace claimable detail</h4>
        {agreements != null && agreements.length > 0 ? (
          agreements.map((item, index) => <Agreement vault={vault} agreement={item} claim={claim} key={index} />)
        ) : (
          <p className="mt-2 text-xs text-white/70 lg:text-sm">no claimable</p>
        )}
        {position != null && position.length > 0 ? (
          <div>
            <h4 className="mt-6 text-xl">Or sweep pToken to vault owner, and exit use ParaSpace app</h4>

            <SelectToken
              className="mt-2"
              tokens={position.map(item => ({ symbol: item.xTokenSymbol, address: item.xTokenAddress }))}
              onChange={handleSweepAsset}
            />
            <Button className="mt-2 min-w-[96px]" disabled={vault == null} loading={sweepLoading} onClick={sweepFn}>
              Sweep
            </Button>
            <div>
              <a
                href={'https://app.para.space/'}
                className="mt-2 inline-flex items-center gap-2 text-sm hover:opacity-70"
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                <span className="text-white/70">Go to ParaSpace app</span>
                <i className="w-4 opacity-30">
                  <Link />
                </i>
              </a>
            </div>
          </div>
        ) : null}
      </div>
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Automate (Beta)</h3>
        <h4 className="mt-5 text-xl">Automate gas funds</h4>
        <div>
          <a
            className="mt-2 inline-flex items-center gap-2 text-xs hover:opacity-70"
            href={`${blockExplorerUrl}/address/${fundsAddress}`}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
              {ellipsizeText({ text: fundsAddress })}
            </span>
            <i className="w-4 opacity-30">
              <Link />
            </i>
          </a>
        </div>
        <p className="mt-2 text-sm text-white/70">{`Funds balance: ${fundsBalance ?? '--'}`}</p>
        <div className="mt-2 flex items-center justify-between gap-4">
          <Input
            onChange={event => {
              setDepositAmount(event.target.value);
            }}
            value={depositAmount}
            placeholder="gas amount"
          />
          <Button
            className="min-w-[96px]"
            disabled={vault == null || !hasString(depositAmount)}
            loading={depositAutomateFundsLoading}
            onClick={depositAutomateFundsFn}
          >
            Deposit
          </Button>
        </div>
        <p className="mt-5 text-base lg:text-lg">Create a task to auto exit</p>
        <SelectToken
          tokens={tokens}
          onChange={token => {
            setTaskToken(token?.address);
          }}
        />
        <p className="mt-4 text-xs lg:text-sm">
          {stakePoolLiquidity
            ? `ParaSpace min liquidity to exit, current liquidity is ${stakePoolLiquidity}`
            : 'ParaSpace min liquidity to exit'}
        </p>
        <Input
          className="mt-2"
          placeholder="min liquidity to exit"
          onChange={event => {
            setLiquidityExit(event.target.value);
          }}
          value={liquidityExit}
        />
        <p className="mt-2 text-xs lg:text-sm">
          ParaSpace min liquidity to enter, when the balance of the pool is greater than the set value, it will
          automatically enter
        </p>
        <Input
          className="mt-2"
          placeholder="min liquidity to enter"
          onChange={event => {
            setLiquidityEnter(event.target.value);
          }}
          value={liquidityEnter}
        />
        <p className="mt-2 text-xs lg:text-sm">
          Vault min balance to enter, when the balance of the vault is greater than the set value, it will automatically
          enter
        </p>
        <Input
          className="mt-2"
          placeholder="min balance to enter"
          onChange={event => {
            setVaultEnter(event.target.value);
          }}
          value={vaultEnter}
        />
        <p className="mt-2 text-xs lg:text-sm">
          Min seconds between exiting and entering, used to prevent frequent entering and exiting
        </p>
        <Input
          className="mt-2"
          placeholder="min interval seconds"
          onChange={event => {
            setIntervalTime(event.target.value);
          }}
          value={intervalTime}
        />
        <Button
          className="mt-2 min-w-[96px]"
          disabled={
            vault == null ||
            !hasString(liquidityExit) ||
            !hasString(liquidityEnter) ||
            !hasString(vaultEnter) ||
            !hasString(intervalTime)
          }
          loading={createExitTaskLoading}
          onClick={createExitTaskFn}
        >
          Create
        </Button>
        <p className="mt-2 text-base lg:text-lg">{`Approve a executor to execute the tasks`}</p>
        <div>
          <a
            className="mt-2 inline-flex items-center gap-2 text-xs hover:opacity-70"
            href={`${blockExplorerUrl}/address/${executor}`}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <span className="bg-gradient-to-r from-pink-400 to-violet-400 bg-clip-text text-transparent">
              {ellipsizeText({ text: executor })}
            </span>
            <i className="w-4 opacity-30">
              <Link />
            </i>
          </a>
        </div>
        <Button
          className="mt-2 min-w-[96px]"
          disabled={vault == null || executor == null}
          loading={approveAutomateLoading}
          onClick={approveAutomateFn}
        >
          Approve
        </Button>
      </div>
      <div className="mt-10 rounded-xl bg-black/90 px-5 py-4 md:px-10 md:py-8">
        <h3 className="text-2xl font-bold">Robot</h3>
        <p className="mt-6 text-sm text-white/70">Approve a robot to auto exit</p>
        <div className="mt-4 flex items-center justify-between gap-4">
          <Input
            onChange={event => {
              setRobot(event.target.value);
            }}
            value={robot}
          />
          <Button className="min-w-[96px]" disabled={vault == null} loading={approveLoading} onClick={approveFn}>
            Approve
          </Button>
        </div>
      </div>
    </div>
  );
}

export default App;
